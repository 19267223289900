<template>
  <v-container grid-list-md text-xs-center v-resize="checkMobile" :style="isMobile ? 'padding: 5px !important;' : ''">
    <v-overlay v-if="!isLoading && userOnFreeTariff">
      <v-alert color="info" border="top" class="mx-2">
        <v-row>
          <v-col cols="12" class="text-right mt-0 mb-0 pb-0">
            <v-icon @click="goBack">mdi-close</v-icon>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-icon size="50">mdi-information</v-icon>
          </v-col>
          <v-col cols="12" class="text-center font-bold">
            {{ $t('custom.free_tariff_warning') }}
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="subscribeToPaidTariff()" color="success" class="mx-1 my-1"
              :loading="isLoadingSubscribeToPaidTariff">
              {{ $t('custom.to_paid_plan') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn small text @click="toTariffOnSite()" class="mx-1 my-1">
              {{ $t('custom.tariff_more_info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card :style="isMobile ? 'padding: 5px !important;' : ''">
      <v-card-title :class="isMobile ? 'pb-0' : ''">
        <v-spacer></v-spacer>
        <h2 v-if="!isMobile">{{ $t("counts.add_count") }}</h2>
        <h4 v-else>{{ $t("counts.add_count") }}</h4>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text :style="isMobile ? 'padding: 2px !important;' : ''">
        <v-container grid-list-md :style="isMobile ? 'padding: 2px !important;' : ''">
          <v-container :style="isMobile ? 'padding: 2px !important;' : ''">
            <v-flex md12 :class="isMobile ? 'pb-3' : 'd-flex pb-3'">
              <v-flex>
                <v-switch v-model="internal_transfer" :disabled="debts_count" :label="$t('counts.internal_1')"
                  @change="internal_transfer ? setInternalTransfer() : ''" hide-details dense v-tooltip="{
                    content: $t('tooltips.worker_to_worker'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"></v-switch>
              </v-flex>
              <v-flex>
                <v-switch v-model="debts_count" :label="$t('debts.pay')" hide-details dense v-tooltip="{
                  content: $t('tooltips.pay_debt_client'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"></v-switch>
              </v-flex>
              <v-flex>
                <v-switch v-model="count.completed"
                  :label="count.completed ? $t('counts.completed') : $t('counts.not_completed')" hide-details dense
                  v-tooltip="{
                    content: $t('tooltips.count_completed'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"></v-switch>
              </v-flex>
            </v-flex>
            <v-divider class="mb-2"></v-divider>
            <v-layout row wrap>
              <v-flex xs12 sm1 md1>
                <v-text-field v-model.number="count.sum" :label="$t('custom.amount')" hide-no-data
                  hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3 v-if="!internal_transfer">
                <v-autocomplete v-model="selected_count" item-text="text" item-value="value"
                  :items="!debts_count ? counts_groups : counts_groups_debts" :label="$t('custom.select_group')"
                  hide-no-data hide-details></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3 md3 v-if="!internal_transfer && !debts_count">
                <v-autocomplete v-model="selected_car" item-text="code" item-value="id" text hide-no-data hide-details
                  :label="$t('custom.select_car')" :items="cars">
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3 md3 v-if="internal_transfer">
                <v-autocomplete v-model="selected_user" item-text="name" item-value="id" text hide-no-data hide-details
                  :label="$t('other.select_worker')" :items="workers_names">
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3 md3 v-if="debts_count">
                <v-autocomplete v-model="selected_debt" item-text="text_id" item-value="id" type="text"
                  :loading="isLoadingDebts" :search-input.sync="search_debt" flat hide-no-data hide-details
                  :label="$t('tooltips.number_debt')" :items="debts">
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 sm3 md3>
                <v-radio-group v-model="count.operation" row>
                  <v-radio :label="$t('custom.income')" :value="true" @change="operationCheck = true"></v-radio>
                  <v-spacer v-if="isMobile"></v-spacer>
                  <v-radio :label="$t('custom.expences')" :value="false" :disabled="internal_transfer || debts_count"
                    @change="operationCheck = true"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex v-if="!isMobile" xs12 sm12 md12 class="d-inline-flex align-center">
                <v-checkbox v-if="company && company.cash_payment" v-model="count.cash"
                  :disabled="count.cashless || count.cash_card || count.entity" class="mt-0 mr-2" dense hide-details
                  :style="count.cashless || count.cash_card || count.entity
                    ? 'opacity: 0.4;'
                    : ''
                    ">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="success" size="30">
                      mdi-cash
                    </v-icon>
                    <span>{{ $t("money.cash") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox v-if="company && company.cashless_payment" v-model="count.cashless"
                  :label="$t('money.terminal')" :disabled="count.cash ||
                    count.cash_card ||
                    count.entity ||
                    internal_transfer
                    " class="mt-0 mr-2" dense hide-details :style="count.cash ||
                      count.cash_card ||
                      count.entity ||
                      internal_transfer
                      ? 'opacity: 0.4;'
                      : ''
                      " v-tooltip="{
                        content: $t('tooltips.terminal_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="warning">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                    <span>{{ $t("money.terminal") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox v-if="company && company.card_payment" v-model="count.cash_card" :disabled="count.cash ||
                  count.cashless ||
                  count.entity ||
                  internal_transfer
                  " class="mt-0 mr-2" dense hide-details :style="count.cash ||
                    count.cashless ||
                    count.entity ||
                    internal_transfer
                    ? 'opacity: 0.4;'
                    : ''
                    " v-tooltip="{
                      content: $t('money.card_to_card_2'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="info">
                      mdi-credit-card-check-outline
                    </v-icon>
                    <span>{{ $t("money.to_card") }}</span>
                  </template>
                </v-checkbox>
                <v-checkbox v-if="company && company.entity_payment" v-model="count.entity"
                  :label="$t('money.to_entity_short')" class="mt-0 mr-0" dense hide-details :disabled="count.cashless ||
                    count.cash_card ||
                    count.cash ||
                    internal_transfer
                    " :style="count.cashless ||
                      count.cash_card ||
                      count.cash ||
                      internal_transfer
                      ? 'opacity: 0.4;'
                      : ''
                      " v-tooltip="{
                        content: $t('money.from_entity_2'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                  <template v-slot:label>
                    <v-icon class="text-center mr-2" color="secondery">
                      mdi-bank
                    </v-icon>
                    <span>{{ $t("money.to_entity") }}</span>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex v-else xs12 sm12 md12 class="d-flex align-center justify-space-between">
                <v-flex v-if="company && company.cash_payment">
                  <v-checkbox v-model="count.cash" color="success" :disabled="count.cashless || count.cash_card || count.entity
                    " class="mt-0 mr-0" dense hide-details :style="count.cashless || count.cash_card || count.entity
                      ? 'opacity: 0.4;'
                      : ''
                      ">
                    <template v-slot:label>
                      <v-icon class="text-center mr-2" color="success" size="30" v-tooltip="{
                        content: $t('tooltips.cash_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                        mdi-cash
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.cashless_payment">
                  <v-checkbox v-model="count.cashless" color="warning" :disabled="count.cash ||
                    count.cash_card ||
                    count.entity ||
                    internal_transfer
                    " class="mt-0 mr-0" dense hide-details :style="count.cash ||
                      count.cash_card ||
                      count.entity ||
                      internal_transfer
                      ? 'opacity: 0.4;'
                      : ''
                      ">
                    <template v-slot:label>
                      <v-icon class="text-center mr-2" color="warning" v-tooltip="{
                        content: $t('tooltips.terminal_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                        mdi-contactless-payment-circle-outline
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.card_payment">
                  <v-checkbox v-model="count.cash_card" color="info" :disabled="count.cash ||
                    count.cashless ||
                    count.entity ||
                    internal_transfer
                    " class="mt-0 mr-0" dense hide-details :style="count.cash ||
                      count.cashless ||
                      count.entity ||
                      internal_transfer
                      ? 'opacity: 0.4;'
                      : ''
                      ">
                    <template v-slot:label>
                      <v-icon class="text-center mr-2" color="info" v-tooltip="{
                        content: $t('money.card_to_card'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                        mdi-credit-card-check-outline
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
                <v-flex v-if="company && company.entity_payment">
                  <v-checkbox v-model="count.entity" color="secondery" class="mt-0 mr-0" dense hide-details :disabled="count.cashless ||
                    count.cash_card ||
                    count.cash ||
                    internal_transfer
                    " :style="count.cashless ||
                      count.cash_card ||
                      count.cash ||
                      internal_transfer
                      ? 'opacity: 0.4;'
                      : ''
                      " v-tooltip="{
                        content: $t('tooltips.entity_count'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }">
                    <template v-slot:label>
                      <v-icon class="text-center" color="secondery">
                        mdi-bank
                      </v-icon>
                    </template>
                  </v-checkbox>
                </v-flex>
              </v-flex>
              <v-flex v-if="count.cashless ||
                count.cash_card ||
                count.cash ||
                count.entity
              " xs12 sm12 md12 class="text-center">
                {{ $t("money.payment_type") }}:
                <span v-if="count.cash">{{ $t("money.cash") }}</span>
                <span v-if="count.cashless">{{ $t("money.terminal") }}</span>
                <span v-if="count.cash_card">{{
                  $t("money.card_to_card")
                  }}</span>
                <span v-if="count.entity">{{ $t("money.to_entity") }}</span>
              </v-flex>
              <v-flex md12 v-if="company_cashboxes && company_cashboxes.length > 1 && !internal_transfer">
                <v-select v-model="count.company_cashbox_id" item-text="name" item-value="id" :items="company_cashboxes"
                  :label="$t('companies.select_cashbox')" hide-no-data hide-details></v-select>
              </v-flex>
              <v-flex md12 v-if="!internal_transfer">
                <v-autocomplete v-model="contractor" item-text="short_name" :items="contractors"
                  :label="$t('contractors.select_contractor')" hide-no-data hide-details return-object></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-textarea v-model="count.description" auto-grow rows="2" :label="$t('counts.count_description')"
                  :placeholder="$t('counts.count_description_plc')"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-flex md12 class="text-center">
            <v-btn v-if="!internal_transfer" large color="success" @click="submit()">{{ $t("custom.save") }}</v-btn>
            <v-btn v-else large color="success" @click="submitInternalTransfer()">{{ $t("custom.save") }}</v-btn>
          </v-flex>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import moment from "moment";
export default {
  data() {
    return {
      count: {
        group: "",
        operation: null,
        description: "",
        car_id: "",
        sum: 0,
        cash: false,
        cashless: false,
        cash_card: false,
        entity: false,
        contractor_id: null,
        completed: true,
        company_cashbox_id: null,
      },
      internal_transfer: false,
      selected_debt: null,
      debts: [],
      isLoadingDebts: false,
      search_debt: null,
      debts_count: false,
      operationCheck: false,
      selected_count: "",
      selected_car: "",
      selected_user: "",
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
        { text: this.$t("groups_counts.rent"), value: "Оплата аренды" },
        {
          text: this.$t("groups_counts.delivery"),
          value: "Доплата за доставку",
        },
        { text: this.$t("groups_counts.checkout"), value: "Доплата за приём" },
        { text: this.$t("groups_counts.fuel"), value: "Доплата за топливо" },
        { text: this.$t("groups_counts.clean"), value: "Доплата за мойку" },
        {
          text: this.$t("groups_counts.equipment"),
          value: "Доплата за оборудование",
        },
        {
          text: this.$t("groups_counts.add_time"),
          value: "Доплата за дополнительное время",
        },
        {
          text: this.$t("groups_counts.damages"),
          value: "Доплата за повреждения",
        },
        { text: this.$t("groups_counts.fines"), value: "Доплата за штрафы" },
        { text: this.$t("groups_counts.other"), value: "Доплата за другое" },
        {
          text: this.$t("groups_counts.mileage"),
          value: "Доплата за превышение пробега",
        },
        {
          text: this.$t("groups_counts.insurance"),
          value: "Доплата за страховку",
        },
        {
          text: this.$t("groups_counts.add_drivers"),
          value: "Доплата за доп водителей",
        },
        { text: this.$t("groups_counts.salary"), value: "Зарплата" },
        {
          text: this.$t("groups_counts.partners_payments"),
          value: "Выплаты партнёрам",
        },
      ],
      counts_groups_debts: [
        { text: this.$t("groups_counts.rent"), value: "Оплата аренды" },
        {
          text: this.$t("groups_counts.delivery"),
          value: "Доплата за доставку",
        },
        { text: this.$t("groups_counts.checkout"), value: "Доплата за приём" },
        { text: this.$t("groups_counts.fuel"), value: "Доплата за топливо" },
        { text: this.$t("groups_counts.clean"), value: "Доплата за мойку" },
        {
          text: this.$t("groups_counts.equipment"),
          value: "Доплата за оборудование",
        },
        {
          text: this.$t("groups_counts.add_time"),
          value: "Доплата за дополнительное время",
        },
        {
          text: this.$t("groups_counts.damages"),
          value: "Доплата за повреждения",
        },
        { text: this.$t("groups_counts.fines"), value: "Доплата за штрафы" },
        { text: this.$t("groups_counts.other"), value: "Доплата за другое" },
        {
          text: this.$t("groups_counts.mileage"),
          value: "Доплата за превышение пробега",
        },
        {
          text: this.$t("groups_counts.insurance"),
          value: "Доплата за страховку",
        },
        {
          text: this.$t("groups_counts.add_drivers"),
          value: "Доплата за доп водителей",
        },
      ],
      cars: [],
      contractors: [],
      contractor: {},
      workers_names: [],
      isMobile: false,
      isLoading: true,
      isLoadingSubscribeToPaidTariff: false,
      error: "",
    };
  },
  created() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/index_for_add_count", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars = response.data.cars;
          this.contractors = response.data.contractors;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    selected_count() {
      // Выбор доходной или расходной операции
      if (this.selected_count != "") {
        if (
          this.selected_count == "Ремонт" ||
          this.selected_count == "Обслуживание" ||
          this.selected_count == "Запчасти" ||
          this.selected_count == "Страховка" ||
          this.selected_count == "Накладные расходы" ||
          this.selected_count == "Транспортные расходы" ||
          this.selected_count == "Налоги" ||
          this.selected_count == "Штрафы" ||
          this.selected_count == "Аренда имущества" ||
          this.selected_count == "Мойка автомобилей" ||
          this.selected_count == "Топливо" ||
          this.selected_count == "Реклама" ||
          this.selected_count == "Покупка имущества"
        ) {
          this.count.operation = false;
          this.operationCheck = true;
        } else {
          this.count.operation = true;
          this.operationCheck = true;
        }
      }
    },
    search_debt(val) {
      // Items have already been loaded
      if (this.debts.length > 0) return;

      // Items have already been requested
      if (this.isLoadingDebts) return;

      this.isLoadingDebts = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/all_debts", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.forEach((debt) => {
            debt["text_id"] = debt.id.toString();
            this.debts.push(debt);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingDebts = false));
    },
  },
  computed: {
    userOnFreeTariff() {
      if (this.$store.getters.getMainCompany) {
        return this.$store.getters.getMainCompany.tariff == "Старт" && moment(this.$store.getters.getMainCompany.created_at).isBefore(moment().subtract(7, "day")) ? true : false;
      } else {
        return false;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
    company_cashboxes() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          cashboxes.push({
            id: cashbox.id,
            name: cashbox.name,
          });
        });
        cashboxes.push(
          {
            id: null,
            name: this.$t("companies.cashbox_default"),
          }
        );
        return cashboxes;
      } else {
        return [];
      }
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let count = this.count;
        if (this.count.sum === parseInt(this.count.sum, 10)) {
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.count_add"),
          });
          return;
        }
        let self = this;
        if (
          this.selected_count != "" &&
          (count.sum > 0 || count.sum < 0) &&
          this.operationCheck &&
          (count.cash > 0 ||
            count.cashless > 0 ||
            count.cash_card > 0 ||
            count.entity > 0 ||
            count.cash < 0 ||
            count.cashless < 0 ||
            count.cash_card < 0 ||
            count.entity < 0) &&
          ((this.debts_count && this.selected_debt) ||
            (!this.debts_count && !this.selected_debt))
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/counts",
              {
                counts: {
                  group: self.selected_count,
                  operation: count.operation,
                  description: count.description,
                  car_id: self.selected_car,
                  sum: parseFloat(count.sum.toFixed(2)),
                  cash: count.cash,
                  cashless: count.cashless,
                  cash_card: count.cash_card,
                  entity: count.entity,
                  contractor_id: self.contractor.id,
                  completed: count.completed,
                  company_cashbox_id: count.company_cashbox_id,
                  debt_id:
                    self.debts_count && self.selected_debt
                      ? self.selected_debt
                      : null,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$router.replace("/counts");

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("counts.add_payment"),
                text: this.$t("counts.add_payment_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.count_add"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.count_add"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    submitInternalTransfer() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.count.sum === parseInt(this.count.sum, 10)) {
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.count_add"),
          });
          return;
        }
        let self = this;
        if (this.selected_user == this.$store.getters.getCurrentUser.id) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
          return;
        }
        if (this.selected_user != "" && this.count.sum > 0) {
          this.isLoading = true;
          let sender = self.workers_names.find(
            (worker) => worker.id == self.selected_user
          );
          this.axios
            .post(
              `/api/v1/create_internal_count?sender_id=${self.selected_user}`,
              {
                counts: {
                  group: self.selected_count,
                  operation: true,
                  description: self.count.description,
                  sum: parseFloat(self.count.sum.toFixed(2)),
                  cash: true,
                  source: sender ? sender.name : "",
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$router.replace("/counts");

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("counts.add_payment"),
                text: this.$t("counts.add_payment_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.count_to_kass"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "error",
                title: this.$t("errors.count_to_kass"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setInternalTransfer() {
      this.isLoading = true;
      this.count.cash = true;
      this.count.operation = true;
      this.selected_count = "Внутренние переводы";
      this.axios
        .get("/api/v1/get_workers_names_with_ids", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers_names = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.employers_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    subscribeToPaidTariff() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("custom.to_subscribe_to_paid_tariff_warning"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.yes"),
        cancelButtonText: this.$t("custom.no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value && this.$store.getters.getMainCompany && this.$store.getters.getMainCompany.id) {
          this.isLoadingSubscribeToPaidTariff = true;
          this.axios
            .post(`/api/v1/subscribe_to_paid_v2`,
              {
                main_company_id: self.$store.getters.getMainCompany.id,
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
            .then((response) => {
              this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.success"),
              });
              let main_company = self.$store.getters.getMainCompany;
              main_company.tariff = "Стандарт";
              self.$store.commit("setMainCompany", main_company);
            })
            .catch((error) =>
              this.setError(error, this.$t("errors.error"))
            )
            .finally(() => (this.isLoadingSubscribeToPaidTariff = false));
        }
      });
    },
    toTariffOnSite() {
      window.open(`https://rentprog.${this.$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/${this.$root.$i18n.locale}/tariffs`, '_blank');
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        var fpath = this.PageData.backCrumb.url;
        this.$router
          .push({
            path: fpath,
          })
          .catch((err) => { });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
