<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-text>
        <!-- <v-container grid-list-md>
          <v-layout row wrap align-content-center>

          </v-layout>
          <v-divider v-if="showOperationPanel" class="my-4"></v-divider>
        </v-container> -->
        <!-- <v-layout row wrap> -->
        <v-flex md12>
          <v-card>
            <v-toolbar v-if="!isMobile" height="130px" :color="$vuetify.theme.dark ? '' : 'white'"
              class="mb-3 start-tour-company-counts-1">
              <v-flex>
                <v-flex v-if="company_cashboxes && company_cashboxes.length < 3" md12
                  class="d-flex justify-between-around align-center">
                  <v-flex md6 class="">
                    <v-flex md12 class="d-flex justify-start align-center">
                      <v-text-field v-model="company.cash" :label="$t('counts.cash')" hide-details
                        readonly></v-text-field>
                      <v-text-field v-model="company.cashless" :label="$t('counts.entity')" class="mx-2" hide-details
                        readonly></v-text-field>
                    </v-flex>
                  </v-flex>

                  <v-flex md6 class="">
                    <v-flex md12 class="d-flex justify-start align-center">
                      <v-menu v-model="start_date_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="start_date_format" :label="$t('custom.start_period')"
                            prepend-icon="mdi-calendar" readonly hide-details v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_date" :locale="$i18n.locale" @input="start_date_menu = false"
                          first-day-of-week="1"></v-date-picker>
                      </v-menu>
                      <v-menu v-model="end_date_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="end_date_format" :label="$t('custom.end_period')"
                            prepend-icon="mdi-calendar" readonly hide-details v-bind="attrs" v-on="on"
                            class="ml-3"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_date" :locale="$i18n.locale" @input="end_date_menu = false"
                          first-day-of-week="1"></v-date-picker>
                      </v-menu>
                      <v-icon class="text-left" v-if="start_date || end_date" @click="
                        (start_date = null),
                        (end_date = null),
                        (start_date_format = null),
                        (end_date_format = null),
                        getCounts()
                        ">
                        mdi-close
                      </v-icon>
                    </v-flex>
                  </v-flex>
                </v-flex>
                <v-row v-else>
                  <v-col cols="12">
                    <v-select v-model="selected_cashbox" multiple hide-details :items="company_cashboxes"
                      :label="$t('companies.select_cashbox')"
                      :item-text="item => `${item.name}. ${$t('companies.cashbox_cash')}: ${item.cash}, ${$t('companies.cashbox_cashless')}: ${item.cashless}`"
                      item-value="id"></v-select>
                  </v-col>
                </v-row>
                <v-flex md12 class="d-flex justify-between-around align-center mt-4">
                  <v-select v-model="selected_group" clearable hide-details :items="counts_groups"
                    :label="$t('counts.groups')" item-text="text" item-value="value" class="mr-2"></v-select>
                  <v-select v-model="selected_type" clearable hide-details :items="counts_types"
                    :label="$t('counts.types')" item-text="text" item-value="value" class="mx-2"></v-select>
                  <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
                    clearable hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn small color="success" to="/counts/new">{{ $t("counts.add_count") }}
                    <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
                  </v-btn>
                  <v-btn v-if="checkAdmin()" small class="mx-1" color="info" @click="showOperationPanel = true">
                    {{ $t("counts.kass_operations") }}
                    <v-icon class="ml-1"> mdi-cash-register </v-icon>
                  </v-btn>
                  <v-icon v-if="(user_role == 'superadmin' || user_role == 'admin')" @click="toXlsx()" class="ml-2"
                    style="cursor: pointer" color="success">
                    mdi-file-excel-outline
                  </v-icon>
                </v-flex>
              </v-flex>
            </v-toolbar>
            <v-toolbar v-else height="420px" class="mb-4 start-tour-company-counts-1" text
              :color="$vuetify.theme.dark ? '' : 'white'">
              <v-flex md12 class="text-center" justify-center>
                <v-flex md12 class="d-flex mb-3">
                  <v-text-field v-model="company.cash" :label="$t('counts.cash')" hide-details readonly></v-text-field>
                  <v-text-field v-model="company.cashless" :label="$t('counts.entity')" class="mx-2" hide-details
                    readonly></v-text-field>
                </v-flex>
                <v-flex class="mt-3">
                  <v-menu v-model="start_date_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="start_date_format" :label="$t('custom.start_period')" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="start_date" :locale="$i18n.locale" @input="start_date_menu = false"
                      first-day-of-week="1"></v-date-picker>
                  </v-menu>
                  <v-menu v-model="end_date_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="end_date_format" :label="$t('custom.end_period')" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="end_date" :locale="$i18n.locale" @input="end_date_menu = false"
                      first-day-of-week="1"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex v-if="start_date || end_date">
                  {{ $t("custom.remove") }}
                  <v-icon class="text-left" v-if="start_date || end_date" @click="
                    (start_date = null),
                    (end_date = null),
                    (start_date_format = null),
                    (end_date_format = null),
                    getCounts()
                    ">
                    mdi-close
                  </v-icon>
                </v-flex>
                <v-flex>
                  <v-select v-model="selected_group" clearable :items="counts_groups" :label="$t('counts.groups')"
                    item-text="text" item-value="value"></v-select>
                </v-flex>
                <v-flex>
                  <v-select v-model="selected_type" clearable :items="counts_types" :label="$t('counts.types')"
                    item-text="text" item-value="value"></v-select>
                </v-flex>
                <v-flex>
                  <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
                    clearable hide-details></v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-flex md6 class="mt-3">
                    <v-btn small color="success" to="/counts/new">{{ $t("counts.add_count") }}
                      <v-icon class="ml-1"> mdi-plus-circle-outline </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex md4 class="d-flex justify-center align-center mt-1">
                    <v-btn v-if="checkAdmin()" small class="mx-1" icon color="info" @click="showOperationPanel = true">
                      <v-icon class="ml-1"> mdi-cash-register </v-icon>
                    </v-btn>
                    <download-excel :data="filtered_counts" v-if="
                      filtered_counts &&
                      filtered_counts.length > 0 &&
                      (user_role == 'superadmin' || user_role == 'admin')
                    ">
                      <v-icon class="ml-2" style="cursor: pointer" color="success">
                        mdi-file-excel-outline
                      </v-icon>
                    </download-excel>
                  </v-flex>
                </v-flex>
              </v-flex>
            </v-toolbar>
            <v-data-table :headers="headers" :items="filtered_counts" :search="search" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :items-per-page="20" :dense="isMobile" v-resize="checkMobile"
              mobile-breakpoint="100" :sort-by="['created_at']" :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr :class="checkCountState(props.item)">
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center" nowrap>
                    {{ formatCreatedAt(props.item.created_at) }}
                    <div class="caption" v-if="props.item.old_created_at">
                      {{ formatCreatedAt(props.item.old_created_at) }}
                    </div>
                  </td>
                  <td class="text-center">
                    {{ setWorker(props.item.user_id) }}
                  </td>
                  <td class="text-center" nowrap>
                    <v-icon @click="editCount(props.item)">
                      mdi-pencil-outline
                    </v-icon>
                    <v-icon @click="cancelCount(props.item)" v-if="
                      checkAdmin &&
                      !props.item.canceled &&
                      props.item.group != 'Внутренние переводы'
                    " color="warning" v-tooltip="{
                      content: $t('tooltips.cancel_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-credit-card-refund-outline
                    </v-icon>
                  </td>
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">
                    {{ findCashboxName(props.item.company_cashbox_id) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon class="text-center" color="success" v-tooltip="{
                      content: $t('tooltips.cash_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon class="text-center" color="warning" v-tooltip="{
                      content: $t('tooltips.terminal_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon class="text-center" color="secondery" v-tooltip="{
                      content: $t('tooltips.entity_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon class="text-center" color="info" v-tooltip="{
                      content: $t('tooltips.card_to_card'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.client_balance">
                    <v-icon class="text-center" color="error" v-tooltip="{
                      content: $t('money.client_balance'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-arrow-right
                    </v-icon>
                  </td>
                  <td class="text-center">
                    <router-link v-if="props.item.contractor_id"
                      :to="{ path: `/contractors/${props.item.contractor_id}` }">
                      {{ findContractorName(props.item.contractor_id) }}
                    </router-link>
                    <span v-else> - </span>
                  </td>
                  <td class="text-center" nowrap v-if="
                    props.item.booking_id &&
                    props.item.car_id &&
                    props.item.car_code
                  ">
                    <p>
                      {{ $t("bookings.booking") }}:
                      <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">
                        {{ props.item.booking_id }}
                      </router-link>
                    </p>
                    <p>
                      {{ $t("custom.car") }}:
                      <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                        {{ props.item.car_code }}
                      </router-link>
                    </p>
                  </td>
                  <td class="text-center" v-else-if="props.item.car_id && props.item.car_code">
                    <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{
                      props.item.car_code
                    }}</router-link>
                  </td>
                  <td class="text-center" v-else-if="props.item.source">
                    {{ translateCountSource(props.item.source) }}
                  </td>
                  <td class="text-center" v-else-if="props.item.investor_id">
                    <router-link :to="{ path: `/investors/${props.item.investor_id}` }">{{ $t("employers.partner") }} {{
                      $t("custom.number")
                    }}{{ props.item.investor_id }}</router-link>
                  </td>
                  <td class="text-center" v-else-if="props.item.debt_id">
                    {{ $t("debts.debt") }} {{ $t("custom.number")
                    }}{{ props.item.debt_id }}
                  </td>
                  <td class="text-center" v-else-if="props.item.agent_id">
                    {{ $t("agents.agent") }} {{ $t("custom.number") }}
                    <router-link :to="{ path: `/agent_card/${props.item.agent_id}` }">
                      {{ props.item.agent_id }}
                    </router-link>
                  </td>
                  <td class="text-center" v-else>-</td>
                  <td class="text-center">
                    <v-icon v-if="props.item.completed" color="success" v-tooltip="{
                      content: $t('counts.completed'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" @click="completedTooltipDialog(props.item)">
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-else color="error" v-tooltip="{
                      content: $t('counts.not_completed'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" @click="processedCount(props.item)">
                      mdi-alert-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center">
                    <v-menu offset-y v-if="props.item.check == null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-tooltip="{
                          content: $t('tooltips.some_invoice'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }" v-bind="attrs" v-on="on" color="secondary">
                          mdi-map-marker-question-outline
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item v-for="(state, index) in countStates" :key="index"
                          @click="updateСountCheck(props.item.id, state.state)">
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="props.item.check == true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-tooltip="{
                          content: $t('tooltips.trust_invoice'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }" v-bind="attrs" v-on="on" color="success">
                          mdi-checkbox-outline
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item v-for="(state, index) in countStates" :key="index"
                          @click="updateСountCheck(props.item.id, state.state)">
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu offset-y v-if="props.item.check == false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-tooltip="{
                          content: $t('tooltips.bad_invoice'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }" v-bind="attrs" v-on="on" color="error">
                          mdi-bookmark-remove-outline
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item v-for="(state, index) in countStates" :key="index"
                          @click="updateСountCheck(props.item.id, state.state)">
                          <v-list-item-title>
                            {{ state.text }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
        <!-- </v-layout>
        </v-container> -->
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" :retain-focus="false" persistent :fullscreen="true">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container grid-list-md :style="isMobile ? 'padding: 5px !important;' : ''">
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 sm3 md3 v-if="user_role == 'superadmin'">
                  <vc-date-picker v-model="editedCount.created_at" @input="created_at_changed = true" mode="dateTime"
                    :model-config="calendarConfig" :attributes="calendar_attributes" is24hr title-position="left"
                    class="flex d-flex md12 p-0" :locale="$i18n.locale" :is-dark="$vuetify.theme.dark">
                    <template v-slot="{
                      inputValue,
                      inputEvents,
                      isDragging,
                      showPopover,
                    }">
                      <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                        <v-text-field @click="showPopover" :class="isDragging
                          ? 'text-gray-600 mt-0 p-0'
                          : 'text-gray-900 mt-0 p-0'
                          " :value="inputValue" v-on="inputEvents" :label="$t('custom.created_at')" readonly
                          hide-details></v-text-field>
                      </v-flex>
                    </template>
                  </vc-date-picker>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field :readonly="editedCount.completed == true" v-model.number="editedCount.sum"
                    :label="$t('custom.amount')"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-combobox v-model="selected_count" item-text="text" item-value="value" :items="counts_groups"
                    :label="$t('custom.select_group')"></v-combobox>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field v-if="!showCarSearch" :label="$t('custom.car_long')"
                    :placeholder="$t('custom.car_long')" :value="editedCount.car_code"
                    @click="showCarSearch = true"></v-text-field>
                  <v-autocomplete v-if="showCarSearch" v-model="selected_car" item-text="code" item-value="id"
                    :loading="isLoadingCars" :search-input.sync="searchCar" text hide-no-data hide-details
                    :label="$t('bookings.select_car')" :items="cars" return-object>
                  </v-autocomplete>
                </v-flex>
                <v-flex md12>
                  <v-autocomplete v-model="editedCount.contractor_id" item-text="short_name" item-value="id" clearable
                    :items="contractors" :label="$t('contractors.select_contractor')" hide-no-data
                    hide-details></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea v-model="editedCount.description" :label="$t('counts.count_description')"
                    :placeholder="$t('counts.count_description_plc')" rows="1" auto-grow></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCount()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showOperationPanel" :retain-focus="false" :fullscreen="isMobile ? true : false" max-width="600px"
      :style="isMobile ? 'padding: 5px !important;' : ''">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("counts.kass_operations") }}</span>
        </v-card-title>
        <v-card-text :style="isMobile ? 'padding: 15px !important;' : ''">
          <v-flex md12 class="">
            <v-flex md12 class="d-flex"
              v-if="company_cashboxes_without_all && company_cashboxes_without_all.length > 1">
              <v-select v-model="selected_cashbox_in_operations" hide-details :items="company_cashboxes_without_all"
                :label="$t('companies.select_cashbox')" item-text="name" item-value="id" return-object></v-select>
            </v-flex>
            <v-flex md12 class="d-flex" v-if="selected_cashbox_in_operations.id === null">
              <v-text-field v-model="company.cash" :label="$t('counts.cash')" hide-details readonly></v-text-field>
              <v-text-field v-model="company.cashless" :label="$t('counts.entity')" class="mx-2" hide-details
                readonly></v-text-field>
            </v-flex>
            <v-flex md12 class="d-flex" v-else>
              <v-text-field v-model="selected_cashbox_in_operations.cash" :label="$t('counts.cash')" hide-details
                readonly></v-text-field>
              <v-text-field v-model="selected_cashbox_in_operations.cashless" :label="$t('counts.entity')" class="mx-2"
                hide-details readonly></v-text-field>
            </v-flex>
            <v-flex md12 class="d-flex mt-3">
              <v-text-field :disabled="give_cash > 0" v-model.number="get_cash" hide-details
                :label="$t('counts.get_kass')"></v-text-field>
              <v-text-field :disabled="get_cash > 0" v-model.number="give_cash" hide-details class="mx-2"
                :label="$t('counts.give_kass')"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-flex md12 class="d-flex justify-between-center align-center mt-2">
                <v-flex class="mt-0">
                  <v-radio-group v-model="cash_or_cashless" hide-details class="mt-0 align-top" row>
                    <v-radio :label="$t('counts.cashless')" v-tooltip="{
                      content: $t('tooltips.entity_payment'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 300,
                      },
                    }" :value="true"></v-radio>
                    <v-radio :label="$t('counts.cash_2')" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex class="mt-0">
                  <v-radio-group v-model="internal_external" hide-details class="mt-0 align-top" row>
                    <v-radio v-if="checkSuperAdmin()" :label="$t('counts.external')" :value="true"></v-radio>
                    <v-radio :label="$t('counts.internal')" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-flex>
              <v-flex md12>
                <v-textarea v-model="count_description" rows="2" clearable
                  :label="$t('counts.description')"></v-textarea>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showOperationPanel = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveOperation()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProcessedInfoDialog" :retain-focus="false" persistent max-width="500px">
      <v-card>
        <v-card-title v-if="workers && workers.length > 0">
          {{ `${$t('counts.completed')}: ${formatCompletedAt(
            editedCount.completed_at
          )}, ${findWorkersName([editedCount.completed_by])}` }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showProcessedInfoDialog = false, editedCount = {}">{{
            $t("custom.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import xlsx from "json-as-xlsx";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      counts: [],
      contractors: [],
      cars: [],
      search: "",
      workers: [],
      contractors: [],
      account: {
        cash: 0,
        cashless: 0,
      },
      editedCount: [],
      created_at_changed: false,
      selected_car: null,
      selected_count: null,
      get_cash: 0,
      give_cash: 0,
      money_track: false,
      cash_or_cashless: false,
      internal_external: false,
      count_description: "",
      showProcessedInfoDialog: false,
      showCarSearch: false,
      isLoadingCars: false,
      searchCar: null,
      start_date: null,
      end_date: null,
      start_date_format: null,
      end_date_format: null,
      start_date_menu: false,
      end_date_menu: false,
      countStates: [
        { state: true, text: this.$t("counts.checked") },
        { state: false, text: this.$t("counts.not_approved") },
        { state: null, text: this.$t("counts.not_finded") },
      ],
      selected_group: null,
      filtered_counts: [],
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
        { text: this.$t("groups_counts.rent"), value: "Оплата аренды" },
        {
          text: this.$t("groups_counts.delivery"),
          value: "Доплата за доставку",
        },
        { text: this.$t("groups_counts.checkout"), value: "Доплата за приём" },
        { text: this.$t("groups_counts.fuel"), value: "Доплата за топливо" },
        { text: this.$t("groups_counts.clean"), value: "Доплата за мойку" },
        {
          text: this.$t("groups_counts.equipment"),
          value: "Доплата за оборудование",
        },
        {
          text: this.$t("groups_counts.add_time"),
          value: "Доплата за дополнительное время",
        },
        {
          text: this.$t("groups_counts.damages"),
          value: "Доплата за повреждения",
        },
        { text: this.$t("groups_counts.fines"), value: "Доплата за штрафы" },
        { text: this.$t("groups_counts.other"), value: "Доплата за другое" },
        {
          text: this.$t("groups_counts.mileage"),
          value: "Доплата за превышение пробега",
        },
        {
          text: this.$t("groups_counts.insurance"),
          value: "Доплата за страховку",
        },
        {
          text: this.$t("groups_counts.add_drivers"),
          value: "Доплата за доп водителей",
        },
        {
          text: this.$t("groups_counts.internal"),
          value: "Внутренние переводы",
        },
        { text: this.$t("groups_counts.deposit"), value: "Залог" },
        { text: this.$t("groups_counts.salary"), value: "Зарплата" },
        {
          text: this.$t("groups_counts.partners_payments"),
          value: "Выплаты партнёрам",
        },
      ],
      counts_types: [
        { text: this.$t("money.cash"), value: "cash" },
        { text: this.$t("money.terminal"), value: "cashless" },
        { text: this.$t("money.card_to_card"), value: "cash_card" },
        { text: this.$t("money.from_entity"), value: "entity" },
        { text: this.$t("money.client_balance"), value: "client_balance" },
      ],
      selected_type: null,
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.creator"), value: "user_id", align: "center" },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        { text: this.$t("counts.groups"), align: "center" },
        { text: this.$t("companies.count"), value: "company_cashbox_id", align: "center" },
        {
          text: this.$t("custom.description"),
          sortable: false,
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        {
          text: this.$t("contractors.contractor"),
          value: "contractor_id",
          align: "center",
        },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("counts.completed"), value: "completed", align: "center" },
        { text: this.$t("custom.state"), value: "check", align: "center" },
      ],
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm",
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "red",
            fillMode: "outline",
          },
          dates: new Date(),
        },
      ],
      selected_cashbox: [-1],
      selected_cashbox_in_operations: {
        id: null,
        name: this.$t("companies.cashbox_default"),
      },
      pagination: { sortBy: "created_at", descending: true },
      showOperationPanel: false,
      showKassOperation: false,
      editDialog: false,
      isLoading: true,
      isMobile: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin) {
        this.isLoading = true;
        this.axios
          .get("/api/v1/company_counts_new", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.counts = [];
            this.filtered_counts = [];
            response.data.counts.data.forEach((count) => {
              this.counts.push(count.attributes);
              this.filtered_counts.push(count.attributes);
            });
            this.workers = response.data.workers;
            // this.company = response.data.company.data.attributes;
            // this.$store.commit("setCompany", { company: response.data.company.data.attributes });

            this.contractors = [];
            response.data.contractors.data.forEach((contractor) => {
              this.contractors.push(contractor.attributes);
            });
            if (this.$route.params != {} && this.$route.params.query) {
              this.search = this.$route.params.query;
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.counts_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
          text: this.$t("errors.only_admins"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    searchCar(val) {
      // Items have already been loaded
      if (this.cars.length > 0) return;

      // Items have already been requested
      if (this.isLoadingCars) return;

      this.isLoadingCars = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingCars = false));
    },
    start_date() {
      if (this.start_date) {
        this.start_date_format = moment(this.start_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        if (this.start_date && this.end_date) {
          this.getCounts();
        }
      }
    },
    end_date() {
      if (this.end_date) {
        this.end_date_format = moment(this.end_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );

        this.getCounts();
      }
    },
    selected_group() {
      if (this.selected_group) {
        this.selected_type = null;
        let self = this;
        this.filtered_counts = this.counts.filter((count) => {
          return count.group == self.selected_group;
        });
      } else {
        this.filtered_counts = this.counts;
      }
    },
    selected_type() {
      if (this.selected_type) {
        this.selected_group = null;
        this.filtered_counts = this.counts.filter((count) => {
          if (this.selected_type == "cash") {
            return count.cash == true;
          } else if (this.selected_type == "cashless") {
            return count.cashless == true;
          } else if (this.selected_type == "cash_card") {
            return count.cash_card == true;
          } else if (this.selected_type == "entity") {
            return count.entity == true;
          } else if (this.selected_type == "client_balance") {
            return count.client_balance == true;
          }
        });
      } else {
        this.filtered_counts = this.counts;
      }
    },
    selected_cashbox() {
      if (this.selected_cashbox && this.selected_cashbox.length > 0 && this.selected_cashbox.includes(-1)) {
        this.filtered_counts = this.counts;
      } else if (this.selected_cashbox && this.selected_cashbox.length > 0) {
        this.filtered_counts = this.counts.filter((count) => {
          return this.selected_cashbox.includes(count.company_cashbox_id);
        });
      } else {
        this.filtered_counts = this.counts;
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company;
      }
    },
    company_cashboxes() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          cashboxes.push({
            id: cashbox.id,
            name: cashbox.name,
            cash: cashbox.cash,
            cashless: cashbox.cashless,
          });
        });
        if (cashboxes.filter((cashbox) => cashbox.id == -1).length == 0) {
          cashboxes.push(
            {
              id: -1,
              name: this.$t("companies.all_cashboxes"),
              cash: 0,
              cashless: 0,
            },
            {
              id: null,
              name: this.$t("companies.cashbox_default"),
              cash: this.$store.getters.getCompany.company.cash,
              cashless: this.$store.getters.getCompany.company.cashless,
            }
          );
        }
        let cash_sum = 0;
        let cashless_sum = 0;
        cashboxes.forEach((cashbox) => {
          if (cashbox.id != -1) {
            cash_sum += cashbox.cash;
            cashless_sum += cashbox.cashless;
          }
        });
        cashboxes.find((cashbox) => {
          if (cashbox.id == -1) {
            cashbox.cash = cash_sum;
            cashbox.cashless = cashless_sum;
          }
        });
        return cashboxes;
      } else {
        return [];
      }
    },
    company_cashboxes_without_all() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          cashboxes.push({
            id: cashbox.id,
            name: cashbox.name,
            cash: cashbox.cash,
            cashless: cashbox.cashless,
          });
        });
        if (cashboxes.filter((cashbox) => cashbox.id == -1).length == 0) {
          cashboxes.push(
            {
              id: null,
              name: this.$t("companies.cashbox_default"),
              cash: this.$store.getters.getCompany.company.cash,
              cashless: this.$store.getters.getCompany.company.cashless,
            }
          );
        }
        let cash_sum = 0;
        let cashless_sum = 0;
        cashboxes.forEach((cashbox) => {
          if (cashbox.id != -1) {
            cash_sum += cashbox.cash;
            cashless_sum += cashbox.cashless;
          }
        });
        cashboxes.find((cashbox) => {
          if (cashbox.id == -1) {
            cashbox.cash = cash_sum;
            cashbox.cashless = cashless_sum;
          }
        });
        return cashboxes;
      } else {
        return [];
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    saveOperation() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/create_cashbox_count?external=${self.internal_external}`,
            {
              counts: {
                sum: self.get_cash > 0 ? self.get_cash.toFixed(2) : self.give_cash.toFixed(2),
                operation: self.get_cash > 0 ? false : true,
                cash: self.cash_or_cashless ? false : true,
                entity: self.cash_or_cashless ? true : false,
                description: self.count_description,
                group: "Внутренние переводы",
                company_cashbox_id: self.selected_cashbox_in_operations && self.selected_cashbox_in_operations.id ? self.selected_cashbox_in_operations.id : null,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.showOperationPanel = false;
            if (this.selected_cashbox_in_operations.id === null) {
              let company_data = this.company;
              if (!this.cash_or_cashless) {
                company_data.cash -= self.get_cash > 0 ? self.get_cash.toFixed(2) : 0;
                company_data.cash += self.give_cash > 0 ? self.give_cash.toFixed(2) : 0;
              } else {
                company_data.cashless -= self.get_cash > 0 ? self.get_cash.toFixed(2) : 0;
                company_data.cashless += self.give_cash > 0 ? self.give_cash.toFixed(2) : 0;
              }
              this.$store.commit("setCompany", { company: company_data });
            } else {
              let cashbox = this.company_cashboxes.find((cashbox) => cashbox.id == this.selected_cashbox_in_operations.id);
              if (!this.cash_or_cashless) {
                cashbox.cash -= self.get_cash > 0 ? self.get_cash.toFixed(2) : 0;
                cashbox.cash += self.give_cash > 0 ? self.give_cash.toFixed(2) : 0;
              } else {
                cashbox.cashless -= self.get_cash > 0 ? self.get_cash.toFixed(2) : 0;
                cashbox.cashless += self.give_cash > 0 ? self.give_cash.toFixed(2) : 0;
              }
            }

            this.counts.unshift(response.data);
            this.filtered_counts.unshift(response.data);
            this.get_cash = 0;
            this.give_cash = 0;
            this.cash_or_cashless = false;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.updated"),
              text: this.$t("counts.updated_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.kass_change"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.kass_change"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editCount(count) {
      this.editDialog = true;
      this.axios
        .get(`/api/v1/counts/${count.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCount = response.data;
          this.selected_count = {
            text: this.translateCategory(this.editedCount.group),
            value: this.editedCount.group,
          };
          // this.selected_car = this.editedCount.car_id
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.update_count"));
          console.log(error);
        });
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    translateCountSource(source) {
      let source_text = "";
      switch (source) {
        case "Касса компании":
          source_text = this.$t("counts.company_counts");
          break;
        case "Внешний перевод":
          source_text = this.$t("counts_groups.external_transfers");
          break;
        default:
          source_text = source;
          break;
      }
      return source_text;
    },
    saveCount() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let count = this.editedCount;
        let self = this;
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                created_at: this.created_at_changed ? count.created_at : null,
                sum: count.sum.toFixed(2),
                operation: count.operation,
                cashless: count.cashless,
                description: count.description,
                group: this.selected_count
                  ? this.selected_count.group
                  : count.group,
                car_id: this.selected_car ? this.selected_car.id : count.car_id,
                contractor_id: count.contractor_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.counts.findIndex(
              (element) => element.id === count.id
            );

            let count_data = response.data;
            if (this.selected_car) {
              count_data.car_code = this.selected_car.code;
            }

            this.counts.splice(foundIndex, 1, count_data);
            let filtered_index = this.filtered_counts.findIndex(
              (element) => element.id === count.id
            );

            let filtered_count_data = response.data;
            if (this.selected_car) {
              filtered_count_data.car_code = this.selected_car.code;
            }

            this.filtered_counts.splice(filtered_index, 1, filtered_count_data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.update_count_title"),
              text: this.$t("counts.update_count_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.update_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    processedCount(count) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_count`,
                {
                  count_id: count.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let foundIndex = this.counts.findIndex(
                  (element) => element.id === count.id
                )
                let filtered_index = this.filtered_counts.findIndex(
                  (element) => element.id === count.id
                )
                this.counts.splice(foundIndex, 1, response.data);
                this.filtered_counts.splice(filtered_index, 1, response.data);

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    cancelCount(count) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("counts.cancel_question_title"),
          text: this.$t("counts.cancel_question_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("counts.yes_cancel"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .post(
                `/api/v1/cancel_count/${count.id}`,
                {
                  counts: {
                    cancel_count: true,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                // // Добавляем аннулирующий счет
                // this.counts.unshift(response.data.new_count)
                this.filtered_counts.unshift(response.data.new_count);

                // Ищем аннулированный счет и делаем его аннулированным
                let filtered_index = this.filtered_counts.findIndex((count) => {
                  count.id == response.data.old_count;
                });
                this.filtered_counts.splice(
                  filtered_index,
                  1,
                  response.data.old_count
                );

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.canceled_success"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.count_cancel"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.count_cancel"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getCounts() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(
            `/api/v1/company_counts?start_date=${this.start_date}&end_date=${this.end_date}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.counts = [];
            this.filtered_counts = [];
            response.data.data.forEach((count) => {
              self.counts.push(count.attributes);
              self.filtered_counts.push(count.attributes);
            });
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.counts_load"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.counts_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.start_date || this.end_date) {
      } else {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/company_counts`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.counts = response.data;
          })
          .catch((error) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.counts_load"),
              text: this.error,
            });
            this.setError(error, this.$t("errors.counts_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    updateСountCheck(id, state) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.isLoading = true;
        let self = this;
        let count = this.counts.find((element) => element.id === id);
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                check: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.counts.findIndex(
              (element) => element.id === id
            );
            count.check = response.data.check;
            this.counts.splice(foundIndex, 1, count);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.state_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.count_state"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.count_state"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkCountState(count) {
      let state = "";
      if (count.operation && count.sum < 0) {
        state = "red--text";
      }
      if (count.canceled) {
        state = `${state} text-decoration-line-through`;
      }
      return state;
    },
    toXlsx() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let data = [
          {
            sheets: "Payments",
            columns: [
              { label: "ID", value: "id" },
              { label: this.$t("custom.created_at"), value: "created_at" },
              { label: this.$t("custom.amount"), value: "sum" },
              { label: this.$t("custom.operation"), value: "operation" },
              { label: this.$t("counts.about"), value: "description" },
              { label: this.$t("money.terminal"), value: "cashless" },
              { label: this.$t("money.card_to_card"), value: "cash_card" },
              { label: this.$t("money.cash"), value: "cash" },
              { label: this.$t("money.from_entity"), value: "entity" },
              {
                label: this.$t("custom.creator"), value: (row) => {
                  return this.setWorker(row.user_id)
                }
              },
              {
                label: this.$t("custom.group"), value: (row) => {
                  return this.translateCategory(row.group);
                }
              },
              {
                label: this.$t("companies.cashbox_name"), value: (row) => {
                  return this.findCashboxNameForExcel(row.company_cashbox_id);
                }
              },
              { label: this.$t("custom.car"), value: "car_code" },
              { label: this.$t("bookings.booking"), value: "booking_id" },
              { label: this.$t("debts.debt"), value: "debt_id" },
              { label: this.$t("employers.partner"), value: "investor_id" },
              { label: this.$t("counts.completed"), value: "completed" },
            ],
            content: this.filtered_counts && this.filtered_counts.length > 0 ? this.filtered_counts : this.counts,
          },
        ];
        let settings = {
          fileName: "payments", // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        xlsx(data, settings); // Will download the excel file
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setCar(id) {
      if (id != null) {
        if (this.cars.find((car) => car.id == id)) {
          return this.cars.find((car) => car.id == id).code;
        }
      } else {
        return "-";
      }
    },
    setWorker(id) {
      if (id != null) {
        if (this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    findWorkersName(ids) {
      let names = [];
      ids.forEach((id) => {
        this.workers.forEach((worker) => {
          if (worker.id == id) {
            names.push(worker.name);
          }
        });
      });
      return names ? names.join(", ") : "";
    },
    findContractorName(id) {
      if (id != null) {
        if (this.contractors.find((contractor) => contractor.id == id)) {
          return this.contractors.find((contractor) => contractor.id == id)
            .short_name;
        }
      } else {
        return "-";
      }
    },
    findCashboxName(id) {
      if (id != null) {
        if (this.company_cashboxes.find((cashbox) => cashbox.id == id)) {
          return this.company_cashboxes.find((cashbox) => cashbox.id == id).name;
        }
      } else {
        return "-";
      }
    },
    findCashboxNameForExcel(id) {
      if (id != null) {
        if (this.company_cashboxes.find((cashbox) => cashbox.id == id)) {
          return this.company_cashboxes.find((cashbox) => cashbox.id == id).name;
        }
      } else {
        return this.$t("companies.cashbox_default");
      }
    },
    completedTooltipDialog(item) {
      if (item && item.completed_at && item.completed_by) {
        this.getWorkersNames();
        this.showProcessedInfoDialog = true;
        this.editedCount = item;
      } else {
        this.showProcessedInfoDialog = false;
      }
    },
    formatCompletedAt(date) {
      if (date == null) {
        return "-";
      }
      return moment.parseZone(date).format("lll");
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("lll");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
