<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card v-if="
      $store.getters.getMainCompany &&
      $store.getters.getMainCompany.payer_state != 3
    ">
      <v-card-title id="registration-step-48" style="padding-bottom: 0px !important">
        <v-flex>
          <h2 v-if="!isMobile">{{ $t("custom.search_free") }}</h2>
          <h4 v-else>{{ $t("custom.search_free") }}</h4>
          <h4 v-if="showDaysSize" class="subtitle-1">{{ calculateDaysSize() }}</h4>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-flex md12 :class="isMobile ? '' : 'd-flex'">
          <v-flex xs12 sm12 md12>
            <vc-date-picker ref="calendar" is-range v-model="range" mode="dateTime" :model-config="calendarConfig"
              :attributes="calendar_attributes" is24hr :input-debounce="500" title-position="left"
              :columns="$screens({ default: 1, lg: 2 })" class="flex d-flex md12 p-0" :locale="$i18n.locale"
              :min-date="new Date()" :is-dark="$vuetify.theme.dark">
              <template v-slot="{
                inputValue,
                inputEvents,
                togglePopover,
                isDragging,
                showPopover,
              }">
                <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                  <v-text-field @click="togglePopover()" :class="isDragging
                      ? 'text-gray-600 mr-2 p-0'
                      : 'text-gray-900 mr-2 p-0'
                    "
                    :value="inputValue.start || inputValue.end ? `${inputValue.start ? inputValue.start : ''} ${inputValue.start && inputValue.end ? '-' : ''} ${inputValue.end ? inputValue.end : ''}` : $t('custom.start_end_dates')"
                    v-on="inputEvents" prepend-icon="mdi-calendar-clock" readonly hide-details></v-text-field>
                </v-flex>
              </template>
            </vc-date-picker>
          </v-flex>
        </v-flex>
        <v-flex>
          <v-data-table v-if="cars.length > 0" :headers="headers" v-resize="checkMobile" :items="cars"
            :loading="isLoading" :loading-text="$t('custom.loading_table')" :search="search"
            :server-items-length.sync="total" :options.sync="tableOptions" :page.sync="page" item-key="id"
            :class="!isMobile ? 'mt-2 start-tour-bookings-1' : 'mt-2 start-tour-bookings-1 is-mobile'"
            mobile-breakpoint="100" dense :footer-props="{
              pageText: `{0} ${$t('custom.of')} ${total}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [10, 30, 50, 100, -1],
            }">
            <template slot="item" slot-scope="props">
              <tr @dblclick="replaceToNewBooking(props.item)" style="cursor: pointer">
                <td nowrap class="text-left">
                  <v-flex class="d-flex align-middle">
                    <span class="block my-auto">
                      <span class="success--text title">{{ props.item.code }}</span>
                    </span>
                  </v-flex>
                </td>
                <td nowrap class="text-left">
                  <span class="">
                    <span class="caption" style="text-decoration: none !important;" :title="props.item.number">
                      {{ props.item.car_name }}
                    </span>
                    <br>
                    <span class="info--text caption">{{ props.item.number }}</span>
                  </span>
                </td>
                <td nowrap>
                  <v-flex class="d-flex justify-center align-middle">
                    <v-flex class="d-flex mr-1 justify-center align-middle py-1">
                      <v-chip :text-color="$vuetify.theme.dark ? 'white' : 'black'">
                        <v-avatar left>
                          <v-icon class="info--text">mdi-numeric-1-circle-outline</v-icon>
                        </v-avatar>
                        {{ props.item.selected_price }} {{ currency }}
                      </v-chip>
                    </v-flex>
                    <v-flex class="d-flex mr-1 justify-center align-middle py-1">
                      <v-chip :text-color="$vuetify.theme.dark ? 'white' : 'black'">
                        <v-avatar left>
                          <v-icon class="warning--text">mdi-diameter-outline</v-icon>
                        </v-avatar>
                        {{ props.item.total }} {{ currency }}
                      </v-chip>
                    </v-flex>
                    <v-flex class="d-flex mr-1 justify-center align-middle py-1">
                      <v-chip :text-color="$vuetify.theme.dark ? 'white' : 'black'">
                        <v-avatar left>
                          <v-icon class="success--text">mdi-cash-refund</v-icon>
                        </v-avatar>
                        {{ props.item.deposit }} {{ currency }}
                      </v-chip>
                    </v-flex>
                    <v-flex v-if="calculateForHours(props.item)" class="d-flex mr-1 justify-center align-middle py-1">
                      <v-chip :text-color="$vuetify.theme.dark ? 'white' : 'black'">
                        <v-avatar left class="secondary--text" size="24">
                          <v-icon small>mdi-clock-time-eight-outline</v-icon>
                        </v-avatar>
                        {{ calculateForHours(props.item) }} {{ currency }}
                      </v-chip>
                    </v-flex>
                  </v-flex>
                </td>
                <td class="text-center">
                  <v-icon v-if="props.item.automatic" v-tooltip="{
                    content: $t('transmissions.automatic'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 800,
                      hide: 500,
                    },
                  }" color="info">
                    mdi-alpha-a-circle
                  </v-icon>
                  <v-icon v-else v-tooltip="{
                    content: $t('transmissions.manual'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 800,
                      hide: 500,
                    },
                  }" color="brown">
                    mdi-car-shift-pattern
                  </v-icon>
                </td>
                <!-- Состояние -->
                <td class="text-center">
                  <v-icon v-if="props.item.state == 1" v-tooltip="{
                    content: $t('states.normal'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="success">
                    mdi-thumb-up
                  </v-icon>
                  <v-icon v-if="props.item.state == 2" v-tooltip="{
                    content: $t('states.in_service'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="secondary">
                    mdi-tools
                  </v-icon>
                  <v-icon v-if="props.item.state == 3" v-tooltip="{
                    content: $t('states.critical'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="error">
                    mdi-alert-box
                  </v-icon>
                  <v-icon v-if="props.item.state == 4" v-tooltip="{
                    content: $t('states.long_rent'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="pink">
                    mdi-all-inclusive
                  </v-icon>
                  <v-icon v-if="props.item.state == 5" v-tooltip="{
                    content: $t('states.no_rent'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="info">
                    mdi-airplane-off
                  </v-icon>
                  <v-icon v-if="props.item.state == 6" v-tooltip="{
                    content: $t('states.service_needed'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="orange">
                    mdi-alarm-light
                  </v-icon>
                </td>
                <!-- Мойка -->
                <td class="text-center">
                  <v-icon v-if="props.item.clean_state" v-tooltip="{
                    content: $t('states.clean'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="info">
                    mdi-flare
                  </v-icon>
                  <v-icon v-else v-tooltip="{
                    content: $t('states.durty'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="brown">
                    mdi-spray-bottle
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon v-if="props.item.tank_state" v-tooltip="{
                    content: $t('states.gas_full'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="light-green">
                    mdi-battery
                  </v-icon>
                  <v-icon v-else v-tooltip="{
                    content: $t('states.gas_not_full'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }" color="red">
                    mdi-battery-10
                  </v-icon>
                </td>
                <td v-if="user_role && user_role != 'partner' && user_role != 'agent'">
                  {{ props.item.store_place }}
                </td>
                <td>
                  <v-btn icon @click="replaceToNewBooking(props.item)">
                    <v-icon color="pink">
                      mdi-plus-circle
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <p>{{ $t("bookings.warn_balance") }}</p>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import { isMobile } from "mobile-device-detect";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
export default {
  name: "SearchFree",
  data() {
    return {
      cars: [],
      all_days: 1,
      start_date: null,
      end_date: null,
      range: null,
      // car_class: null,
      // car_type: null,
      // auto_gearbox: null,
      // manual_gearbox: null,
      // robot_gearbox: null,
      // gasolina: null,
      // gasoleo: null,
      // electro: null,
      tableOptions: {},
      expandedColumns: [],
      headers: [],
      page: 1,
      per_page: 20,
      total: 0,
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        // timeAdjust: "10:00:00",
        minDate: moment().toISOString(),
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      showDaysSize: false,
      search: null,
      isMobile: false,
      isLoading: false,
      errors: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (!this.$store.getters.isLoggedIn) {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    start_date() {
      if (this.start_date && this.end_date) {
        this.loadFreeCars()
      }
    },
    end_date() {
      if (this.start_date && this.end_date) {
        this.loadFreeCars()
      }
    },
    tableOptions(pagination) {
      this.page = pagination.page;
      this.per_page = pagination.itemsPerPage > 0 ? pagination.itemsPerPage : 1000;
      this.loadFreeCars();
    },
    range() {
      if (this.range) {
        if (this.range.start) {
          this.start_date = moment(this.range.start, "DD-MM-YYYY H:mm").format("DD-MM-YYYY H:mm");
        }
        if (this.range.end) {
          this.end_date = moment(this.range.end, "DD-MM-YYYY H:mm").format("DD-MM-YYYY H:mm");
        }
      } else {
        this.start_date = null;
        this.end_date = null;
      }
    },
  },
  computed: {
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    company() {
      return this.$store.getters.getCompany.company;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    minDate() {
      if (this.start_date) {
        return moment(this.start_date, "DD-MM-YYYY H:mm").toDate();
      } else {
        return undefined;
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
  },
  methods: {
    loadFreeCars() {
      this.showDaysSize = false;
      if (this.start_date && this.end_date && !moment(this.start_date, "DD-MM-YYYY H:mm").isAfter(moment(this.end_date, "DD-MM-YYYY H:mm"))) {
        let car_classes = ["econom", "middle", "business", "commercial"]
        let car_types = [
          "micro",
          "hatchback",
          "compact",
          "sedan",
          "universal",
          "minivan",
          "coupe",
          "crossover",
          "suv",
          "pickup",
          "limousine",
          "van",
          "convertible",
          "microbus",
          "campervan",
          "light_van",
          "roadster",
          "sportcar",
        ]
        let valuesTypes = {
          micro: "Микро",
          hatchback: "Хэтчбек",
          sedan: "Седан",
          universal: "Универсал",
          minivan: "Минивен",
          coupe: "Купе",
          crossover: "Кроссовер",
          suv: "Внедорожник",
          pickup: "Пикап",
          limousine: "Лимузин",
          van: "Фургон",
          convertible: "Кабриолет",
          microbus: "Микроавтобус",
          campervan: "Автодом",
          light_van: "Легковой фургон",
          roadster: "Родстер",
          sportcar: "Спорткар",
          econom: "Эконом",
          middle: "Средний",
          business: "Бизнес",
          commercial: "Коммерческий",
          compact: "Компакт",
        }
        let car_class = null
        let car_type = null
        let self = this;
        this.isLoading = true;
        this.axios
          .post(`/api/v1/free_cars_for_rent`,
            {
              start_date: this.start_date,
              end_date: this.end_date,
              // car_class: car_classes.includes(car_class) ? valuesTypes[car_class] : null,
              // car_type: car_types.includes(car_type) ? valuesTypes[car_type] : null,
              // auto_gearbox: this.auto_gearbox,
              // manual_gearbox: this.manual_gearbox,
              // robot_gearbox: this.robot_gearbox,
              // gasolina: this.gasolina,
              // gasoleo: this.gasoleo,
              // electro: this.electro,
              sort: this.sort,
              page: this.page,
              per_page: this.per_page,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken
              }
            }
          )
          .then((response) => {
            this.cars = [];
            response.data.cars.data.forEach((car) => {
              this.cars.push(car.attributes);
            });
            this.total = response.data.total_count;
            this.showDaysSize = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
          })
          .finally(() => { this.isLoading = false; });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.error"),
          text: this.$t("errors.incorrect_date_text"),
        });
      }
    },
    calculateDaysSize() {
      if (this.start_date && this.end_date && this.cars.length > 0 && this.cars[0].selected_price > 0) {
        let days = this.cars[0].total / this.cars[0].selected_price;
        let full_hours = moment.duration(moment(this.end_date, "DD-MM-YYYY H:mm").diff(moment(this.start_date, "DD-MM-YYYY H:mm"))).asHours();
        let hours = Math.trunc(full_hours % 24);
        if (hours > 0 && this.company.hours_limit && this.company.hours_limit > 0 && hours < this.company.hours_limit) {
          return `${days} ${this.$t("bookings.days_low")} ${hours} ${this.$t("custom.add_hours_low")}`;
        } else {
          return `${days} ${this.$t("bookings.days_low")}`;
        }
      } else {
        return "?";
      }
    },
    calculateForHours(car) {
      let price = 0;
      if (this.start_date && this.end_date) {
        let start_date = moment.utc(this.start_date, "DD-MM-YYYY H:mm");
        let end_date = moment.utc(this.end_date, "DD-MM-YYYY H:mm");

        let full_hours = moment.duration(end_date.diff(start_date)).asHours();
        let hours = Math.trunc(full_hours % 24);
        if (this.company.hours_limit && this.company.hours_limit > 0 && hours < this.company.hours_limit) {
          price = car.price_hour * hours;
        }

      }
      return price;
    },
    replaceToNewBooking(car) {
      this.$router.push({ path: `/booking/new?car_id=${car.id}` });
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  components: {
    // flatPickr
  },
};
</script>
