<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-tabs v-model="tabs" :fixed-tabs="!isMobile" :centered="!isMobile" :vertical="isMobile">
        <v-tab>
          {{ $t("agregator.business_time") }}
        </v-tab>
        <v-tab>
          {{ $t("agregator.locations") }}
        </v-tab>
        <v-tab>
          {{ $t("agregator.payment_methods_title") }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabs" touchless>
        <!-- Время работы компании -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row v-if="business_time" class="mx-5">
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[0].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.monday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.monday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[1].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.tuesday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.tuesday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[2].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.wednesday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.wednesday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[3].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.thursday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.thursday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[4].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.friday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.friday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[5].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.saturday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.saturday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <h3>{{ days[6].value }}</h3>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.sunday[0].start" type="time" step="1"
                    :label="$t('agregator.start_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <v-text-field v-model="business_time.sunday[0].end" type="time" step="1"
                    :label="$t('agregator.end_work_day')" outlined dense hide-details></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveBusinessTime()">{{ $t("custom.save")
                }}<v-icon class="ml-1">
                  mdi-content-save-check-outline
                </v-icon></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Locations -->
        <v-tab-item>
          <v-card>
            <v-expansion-panels>
              <v-expansion-panel
                v-if="$store.getters.getCompany.company && $store.getters.getCompany.company.country == 'Russia'">
                <v-expansion-panel-header>
                  <h4>VseProkaty.ru</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-flex class="d-flex justify-end">
                        <v-btn color="success" small @click="addLocation()">
                          {{ $t("agregator.add_location")
                          }}<v-icon class="ml-1"> mdi-plus </v-icon></v-btn>
                      </v-flex>
                      <v-flex :class="isMobile ? 'text-center' : 'd-flex mx-auto'" md12
                        v-for="(location, index) in locations" :key="index">
                        <v-text-field class="mr-2 my-2" v-model="location.name" :label="$t('agregator.location_name')"
                          outlined dense hide-details></v-text-field>
                        <v-text-field class="mr-2 my-2" v-model="location.price" :label="$t('agregator.location_price')"
                          type="number" outlined dense hide-details></v-text-field>
                        <v-text-field class="mr-2 my-2" v-model="location.extra_price"
                          :label="$t('agregator.location_extra_price')" type="number" outlined dense
                          hide-details></v-text-field>
                        <v-btn v-if="locations && locations.length > 1" :class="isMobile ? 'm-2' : 'ml-2 my-2'"
                          color="error" @click="removeLocation(index)">
                          -
                        </v-btn>
                      </v-flex>
                      <!-- Delivary radius -->
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="my-3">
                          <v-flex class="d-flex justify-start">
                            <v-text-field v-model="delivery_radius" type="number"
                              :label="$t('agregator.delivery_radius')" outlined dense hide-details></v-text-field>
                            <v-btn class="my-auto mx-2" color="success" @click="saveDeliveryRadius()">
                              {{ $t("custom.save") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-flex>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          {{ $t("agregator.delivery_radius_description") }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="locations && locations.length >= 1" color="success" @click="saveLocations()">{{
                        $t("custom.save")
                        }}<v-icon class="ml-1">
                          mdi-content-save-check-outline
                        </v-icon></v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h4>Localrent.com</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item v-for="(location, index) in localrent_locations" :key="index">
                            <v-list-item-content>
                              <v-list-item-title>{{ location.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ location.lat }} {{ location.lon }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select v-model="available_payment_methods" :items="payment_methods" multiple item-text="name"
                    item-value="value" :label="$t('agregator.payment_methods')" outlined dense hide-details></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select v-model="available_deposit_methods" :items="payment_deposit_methods" multiple
                    item-text="name" item-value="value" :label="$t('agregator.payment_methods_deposit')" outlined dense
                    hide-details></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-btn color="success" @click="savePaymentMethods()">{{ $t("custom.save") }}<v-icon class="ml-1">
                      mdi-content-save-check-outline
                    </v-icon></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      booking_settings: null,
      business_time: {
        monday: [{ start: "09:00:00", end: "18:00:00" }],
        tuesday: [{ start: "09:00:00", end: "18:00:00" }],
        wednesday: [{ start: "09:00:00", end: "18:00:00" }],
        thursday: [{ start: "09:00:00", end: "18:00:00" }],
        friday: [{ start: "09:00:00", end: "18:00:00" }],
        saturday: [{ start: "09:00:00", end: "18:00:00" }],
        sunday: [{ start: "09:00:00", end: "18:00:00" }],
      },
      locations: [],
      localrent_locations: [],
      available_payment_methods: [],
      available_deposit_methods: [],
      payment_methods: [
        { name: this.$t("agregator.payment_methods_cash"), value: 1 },
        { name: this.$t("agregator.payment_methods_visa"), value: 2 },
        { name: this.$t("agregator.payment_methods_mastercard"), value: 3 },
        { name: this.$t("agregator.payment_methods_american_express"), value: 4 },
        { name: this.$t("agregator.payment_methods_union_pay"), value: 5 },
        { name: this.$t("agregator.payment_methods_mir"), value: 6 },
        { name: this.$t("agregator.payment_methods_bank_transfer"), value: 7 },
      ],
      payment_deposit_methods: [
        { name: this.$t("agregator.payment_methods_cash"), value: 1 },
        { name: this.$t("agregator.payment_methods_visa_debit"), value: 2 },
        { name: this.$t("agregator.payment_methods_visa_credit"), value: 3 },
        { name: this.$t("agregator.payment_methods_master_card_debit"), value: 4 },
        { name: this.$t("agregator.payment_methods_master_card_credit"), value: 5 },
        { name: this.$t("agregator.payment_methods_american_express_debit"), value: 6 },
        { name: this.$t("agregator.payment_methods_american_express_credit"), value: 7 },
        { name: this.$t("agregator.payment_methods_union_pay_debit"), value: 8 },
        { name: this.$t("agregator.payment_methods_union_pay_credit"), value: 9 },
        { name: this.$t("agregator.payment_methods_mir"), value: 10 },
        { name: this.$t("agregator.payment_methods_mir_credit"), value: 11 },
        { name: this.$t("agregator.payment_methods_bank_transfer"), value: 12 },
      ],
      delivery_radius: 50,
      description: "",
      fast_booking: false,
      days: [
        { name: "monday", value: this.$t("days.monday") },
        { name: "tuesday", value: this.$t("days.tuesday") },
        { name: "wednesday", value: this.$t("days.wednesday") },
        { name: "thursday", value: this.$t("days.thursday") },
        { name: "friday", value: this.$t("days.friday") },
        { name: "saturday", value: this.$t("days.saturday") },
        { name: "sunday", value: this.$t("days.sunday") },
      ],
      tabs: null,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      ) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/company_booking_settings_v2`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.booking_settings = response.data.settings;
            this.business_time = this.booking_settings.business_time;
            this.locations = this.booking_settings.locations;
            this.delivery_radius = this.company.delivery_radius;
            this.available_payment_methods = this.booking_settings.available_payment_methods;
            this.available_deposit_methods = this.booking_settings.available_deposit_methods;
            this.localrent_locations = response.data.locations;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.company_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
          text: this.$t("errors.only_admins_2"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {},
  computed: {
    company() {
      if (this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company;
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    saveBusinessTime() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/update_business_time/${this.booking_settings.id}`,
          {
            business_time: this.business_time,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.booking_settings = response.data;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    savePaymentMethods() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/update_payment_methods`,
          {
            available_payment_methods: this.available_payment_methods,
            available_deposit_methods: this.available_deposit_methods,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveLocations() {
      if (this.locations.length > 0) {
        let good_locations = true;
        this.locations.forEach((location) => {
          if (!(good_locations && this.checkLocation(location))) {
            good_locations = false;
          }
        });
        if (good_locations) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/update_locations/${this.booking_settings.id}`,
              {
                locations: this.locations,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking_settings = response.data;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                icon: "success",
                title: this.$t("custom.saved"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            timer: 3000,
            icon: "error",
            title: this.$t("agregator.location_bad"),
          });
        }
      } else {
        this.$swal({
          showConfirmButton: true,
          timer: 3000,
          icon: "error",
          title: this.$t("agregator.no_locations"),
        });
      }
    },
    saveDeliveryRadius() {
      if (this.delivery_radius && this.delivery_radius > 20 && this.delivery_radius < 300) {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/update_delivery_radius`,
            {
              delivery_radius: this.delivery_radius,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.delivery_radius = response.data.delivery_radius;
            this.$store.commit("setCompany", response.data);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          showConfirmButton: true,
          timer: 3000,
          icon: "error",
          title: this.$t("agregator.delivery_radius_bad"),
          text: this.$t("agregator.delivery_radius_bad_text"),
        });
      }
    },
    checkLocation(location) {
      if (
        location.name &&
        location.price &&
        location.price >= 0 &&
        location.extra_price &&
        location.extra_price >= 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    removeLocation(index) {
      this.locations.splice(index, 1);
    },
    addLocation() {
      this.locations.push({
        id: this.locations.length + 1,
        name: null,
        price: null,
        extra_price: null,
      });
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
