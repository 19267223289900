<template>
  <span v-resize="checkMobile">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-card>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-flex md12>
          <h2 class="mb-2">
            {{ $t("counts.in_count") }}: {{ account.cash.toFixed(1)
            }}{{ currency }}
          </h2>
        </v-flex>
        <v-flex md12>
          <v-card>
            <v-toolbar v-if="!isMobile" text :color="$vuetify.theme.dark ? '' : 'white'" align-content-center
              class="start-tour-counts-1">
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
                clearable hide-details></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small color="success" to="/counts/new">
                {{
                  $t("counts.add_count")
                }}
                <v-icon class="ml-1">mdi-plus-circle-outline</v-icon>
              </v-btn>
              <v-btn v-if="!isIos" icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
                }/${$root.$i18n.locale}/categories/6`" target="_blank">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </v-toolbar>
            <v-toolbar v-else text :color="$vuetify.theme.dark ? '' : 'white'" height="100px" align-content-center
              class="start-tour-counts-1">
              <v-flex md12 class="text-center">
                <v-text-field v-model="search" class="mb-3" append-icon="mdi-magnify" :label="$t('custom.search')"
                  single-line clearable hide-details></v-text-field>
                <v-btn small color="success" to="/counts/new">{{
                  $t("counts.add_count")
                  }}</v-btn>
              </v-flex>
            </v-toolbar>
            <v-data-table :headers="headers" :items="counts" :search="search" :loading="isLoading"
              :loading-text="$t('custom.loading_table')" :items-per-page="20" :dense="isMobile" mobile-breakpoint="100"
              :sort-by="['created_at']" :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr :class="props.item.operation && props.item.sum < 0
                  ? 'red--text'
                  : ''
                  ">
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center" nowrap>
                    {{ returnDate(props.item.created_at) }}
                  </td>
                  <td class="text-center" nowrap>
                    <v-icon class="mr-2" @click="editCount(props.item)">
                      mdi-pencil-outline
                    </v-icon>
                  </td>
                  <td class="text-center">
                    {{ translateCategory(props.item.group) }}
                  </td>
                  <td class="text-center">
                    {{ findCashboxName(props.item.company_cashbox_id) }}
                  </td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center" v-if="props.item.operation">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.operation">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash">
                    <v-icon class="text-center" color="success" v-tooltip="{
                      content: $t('tooltips.cash_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cashless">
                    <v-icon class="text-center" color="warning" v-tooltip="{
                      content: $t('tooltips.terminal_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.entity">
                    <v-icon class="text-center" color="secondery" v-tooltip="{
                      content: $t('tooltips.entity_count'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-bank
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.cash_card">
                    <v-icon class="text-center" color="info" v-tooltip="{
                      content: $t('money.card_to_card'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="props.item.client_balance">
                    <v-icon class="text-center" color="error" v-tooltip="{
                      content: $t('money.client_balance'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }">
                      mdi-account-arrow-right
                    </v-icon>
                  </td>
                  <td class="text-center">
                    <router-link v-if="props.item.contractor_id"
                      :to="{ path: `/contractors/${props.item.contractor_id}` }">
                      {{ findContractorName(props.item.contractor_id) }}
                    </router-link>
                    <span v-else> - </span>
                  </td>
                  <td class="text-left" nowrap v-if="
                    props.item.booking_id &&
                    props.item.car_id &&
                    props.item.car_code
                  ">
                    <p>
                      {{ $t("bookings.booking") }}:
                      <router-link :to="{ path: `/bookings/${props.item.booking_id}` }">
                        {{ props.item.booking_id }}
                      </router-link>
                    </p>
                    <p>
                      {{ $t("custom.car") }}:
                      <router-link :to="{ path: `/cars/${props.item.car_id}` }">
                        {{ props.item.car_code }}
                      </router-link>
                    </p>
                  </td>
                  <td class="text-center" v-else-if="props.item.car_id && props.item.car_code">
                    <router-link :to="{ path: `/cars/${props.item.car_id}` }">{{ props.item.car_code }}</router-link>
                  </td>
                  <td class="text-center" v-else-if="props.item.source">
                    {{ translateCountSource(props.item.source) }}
                  </td>
                  <td class="text-center" v-else-if="props.item.debt_id">
                    {{ $t("debts.debt") }} {{ $t("custom.number")
                    }}{{ props.item.debt_id }}
                  </td>
                  <td class="text-center" v-else-if="props.item.agent_id">
                    {{ $t("agents.agent") }} {{ $t("custom.number") }}
                    <router-link :to="{ path: `/agent_card/${props.item.agent_id}` }">
                      {{ props.item.agent_id }}
                    </router-link>
                  </td>
                  <td class="text-center" v-else-if="props.item.investor_id">
                    <router-link :to="{ path: `/investors/${props.item.investor_id}` }">{{ $t("employers.partner") }} {{
                      $t("custom.number")
                      }}{{ props.item.investor_id }}</router-link>
                  </td>
                  <td class="text-center" v-else>-</td>
                  <td class="text-center">
                    <v-icon v-if="props.item.completed" color="success" v-tooltip="{
                      content: $t('counts.completed'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" @click="completedTooltipDialog(props.item)">
                      mdi-check-circle-outline
                    </v-icon>
                    <v-icon v-else color="error" v-tooltip="{
                      content: $t('counts.not_completed'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }" @click="processedCount(props.item)">
                      mdi-alert-circle-outline
                    </v-icon>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
        <v-flex md12 class="mt-5">
          <h2 class="mb-2">{{ $t("counts.daily_kass") }}</h2>
          <v-card>
            <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
              <v-spacer></v-spacer>
              <v-text-field v-model="search_cashbox" append-icon="mdi-magnify" :label="$t('custom.search')" single-line
                clearable hide-details></v-text-field>
            </v-toolbar>
            <v-data-table :headers="headers_cashboxes" :items="user_cashbox" :search="search_cashbox" dense
              :loading-text="$t('custom.loading_table')" :items-per-page="7" mobile-breakpoint="100"
              :sort-by="['created_at']" :sort-desc="[true]" :class="!isMobile ? '' : 'is-mobile'" :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [7, 14, 50, 100, -1],
              }">
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center">
                    {{ formatCreatedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ props.item.account }}{{ currency }}
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" :retain-focus="false" persistent :fullscreen="isMobile ? true : false"
      max-width="1000px">
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container grid-list-md :style="isMobile ? 'padding: 5px !important;' : ''">
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-text-field readonly v-model.number="editedCount.sum" :label="$t('custom.amount')"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md5>
                  <v-combobox v-model="selected_count" :items="counts_groups" item-text="text" item-value="value"
                    :label="$t('custom.select_group')"></v-combobox>
                </v-flex>
                <v-flex xs12 sm4 md5>
                  <v-text-field v-if="!showCarSearch" :label="$t('custom.car_long')"
                    :placeholder="$t('custom.car_long')" :value="editedCount.car_code"
                    @click="showCarSearch = true"></v-text-field>
                  <v-autocomplete v-if="showCarSearch" v-model="selected_car" item-text="code" item-value="id"
                    :loading="isLoadingCars" :search-input.sync="searchCar" text hide-no-data hide-details clearable
                    :label="$t('bookings.select_car')" :items="cars" return-object>
                  </v-autocomplete>
                </v-flex>
                <v-flex md12>
                  <v-autocomplete v-model="editedCount.contractor_id" item-text="short_name" item-value="id" clearable
                    :items="contractors" :label="$t('contractors.select_contractor')" hide-no-data
                    hide-details></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea v-model="editedCount.description" :label="$t('counts.count_description')"
                    :placeholder="$t('counts.count_description_plc')" rows="1" auto-grow></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">{{
            $t("custom.close")
            }}</v-btn>
          <v-btn color="success" @click="saveCount()">{{
            $t("custom.save")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showProcessedInfoDialog" :retain-focus="false" persistent max-width="500px">
      <v-card>
        <v-card-title v-if="workers && workers.length > 0">
          {{ `${$t('counts.completed')}: ${formatCompletedAt(
            editedCount.completed_at
          )}, ${findWorkersName([editedCount.completed_by])}` }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showProcessedInfoDialog = false, editedCount = {}">{{
            $t("custom.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
export default {
  data() {
    return {
      counts: [],
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
      ],
      user_cashbox: [],
      headers_cashboxes: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        { text: this.$t("custom.date"), value: "created_at", align: "center" },
        { text: this.$t("custom.counts"), value: "account", align: "center" },
      ],
      contractors: [],
      cars: [],
      search: "",
      user: [],
      account: {
        cash: 0,
        cashless: 0,
      },
      editedCount: {},
      selected_car: null,
      selected_count: null,
      showCarSearch: false,
      isLoadingCars: false,
      searchCar: null,
      showProcessedInfoDialog: false,
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        { text: this.$t("companies.count"), value: "company_cashbox_id", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        {
          text: this.$t("contractors.contractor"),
          value: "contractor_id",
          align: "center",
        },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("counts.completed"), value: "completed", align: "center" },
      ],
      workers: [],
      pagination: { sortBy: "created_at", descending: true },
      search_cashbox: "",
      editDialog: false,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
    });
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/user_cashbox`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.user = response.data.user;
          this.account = response.data.account;
          this.user_cashbox = response.data.user_cashbox;
          this.counts = [];
          response.data.counts.data.forEach((count) => {
            this.counts.push(count.attributes);
          });
          this.contractors = [];
          response.data.contractors.data.forEach((contractor) => {
            this.contractors.push(contractor.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_user"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    searchCar(val) {
      // Items have already been loaded
      if (this.cars.length > 0) return;

      // Items have already been requested
      if (this.isLoadingCars) return;

      this.isLoadingCars = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_cars"));
          console.log(error);
        })
        .finally(() => (this.isLoadingCars = false));
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company_cashboxes() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.company_cashboxes) {
        let cashboxes = [];
        this.$store.getters.getCompany.company.company_cashboxes.forEach((cashbox) => {
          cashboxes.push({
            id: cashbox.id,
            name: cashbox.name,
            cash: cashbox.cash,
            cashless: cashbox.cashless,
          });
        });
        if (cashboxes.filter((cashbox) => cashbox.id == -1).length == 0) {
          cashboxes.push(
            {
              id: -1,
              name: this.$t("companies.all_cashboxes"),
              cash: 0,
              cashless: 0,
            },
            {
              id: null,
              name: this.$t("companies.cashbox_default"),
              cash: this.$store.getters.getCompany.company.cash,
              cashless: this.$store.getters.getCompany.company.cashless,
            }
          );
        }
        let cash_sum = 0;
        let cashless_sum = 0;
        cashboxes.forEach((cashbox) => {
          if (cashbox.id != -1) {
            cash_sum += cashbox.cash;
            cashless_sum += cashbox.cashless;
          }
        });
        cashboxes.find((cashbox) => {
          if (cashbox.id == -1) {
            cashbox.cash = cash_sum;
            cashbox.cashless = cashless_sum;
          }
        });
        return cashboxes;
      } else {
        return [];
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    editCount(count) {
      this.editDialog = true;
      this.axios
        .get(`/api/v1/counts/${count.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCount = response.data;
          let group_text = this.translateCategory(this.editedCount.group);

          this.selected_count = {
            text: group_text,
            value: this.editedCount.group,
          };
          // this.selected_car = this.editedCount.car_id
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_cashboxes"));
          console.log(error);
        });
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
        case "Пополнение баланса клиента":
          group_text = this.$t("groups_counts.top_up_client_balance");
          break;
        case "Выплаты агентам":
          group_text = this.$t("groups_counts.agent_payments");
          break;
      }
      return group_text;
    },
    findCashboxName(id) {
      if (id != null) {
        if (this.company_cashboxes.find((cashbox) => cashbox.id == id)) {
          return this.company_cashboxes.find((cashbox) => cashbox.id == id).name;
        }
      } else {
        return "-";
      }
    },
    translateCountSource(source) {
      let source_text = "";
      switch (source) {
        case "Касса компании":
          source_text = this.$t("counts.company_counts");
          break;
        case "Внешний перевод":
          source_text = this.$t("counts_groups.external_transfers");
          break;
        default:
          source_text = source;
          break;
      }
      return source_text;
    },
    saveCount() {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.isLoading = true;
        let count = this.editedCount;
        let self = this;
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                operation: count.operation,
                cashless: count.cashless,
                description: count.description,
                group: this.selected_count
                  ? this.selected_count.group
                  : count.group,
                car_id: this.selected_car ? this.selected_car.id : count.car_id,
                contractor_id: count.contractor_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.counts.findIndex(
              (element) => element.id === count.id
            );

            let count_data = response.data;
            if (this.selected_car) {
              count_data.car_code = this.selected_car.code;
            }
            console.log(count_data);
            this.counts.splice(foundIndex, 1, count_data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: this.$t("counts.update_count_title"),
              text: this.$t("counts.update_count_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              icon: "error",
              title: this.$t("errors.update_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    processedCount(count) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("counts.processed_payment_not_declined"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/processed_count`,
                {
                  count_id: count.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                let foundIndex = this.counts.findIndex(
                  (element) => element.id === count.id
                )
                this.counts.splice(foundIndex, 1, response.data);

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "success",
                  title: this.$t("counts.update_count_title"),
                  text: this.$t("counts.update_count_text"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.update_count"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  icon: "error",
                  title: this.$t("errors.update_count"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setCar(id) {
      if (id != null) {
        if (this.cars.find((car) => car.id == id)) {
          return this.cars.find((car) => car.id == id).code;
        }
      } else {
        return "-";
      }
    },
    getWorkersNames() {
      this.isLoadingResponsible = true;
      this.axios
        .get("/api/v1/get_workers_names_with_ids", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch((error) => {
          this.setError(error, "Something went wrong in get_workers_names");
          console.log(error);
        })
        .finally(() => (this.isLoadingResponsible = false));
    },
    findWorkersName(ids) {
      let names = [];
      ids.forEach((id) => {
        this.workers.forEach((worker) => {
          if (worker.id == id) {
            names.push(worker.name);
          }
        });
      });
      return names ? names.join(", ") : "";
    },
    findContractorName(id) {
      if (id != null) {
        if (this.contractors.find((contractor) => contractor.id == id)) {
          return this.contractors.find((contractor) => contractor.id == id)
            .short_name;
        }
      } else {
        return "-";
      }
    },
    completedTooltipDialog(item) {
      if (item && item.completed_at && item.completed_by) {
        this.getWorkersNames();
        this.showProcessedInfoDialog = true;
        this.editedCount = item;
      } else {
        this.showProcessedInfoDialog = false;
      }
    },
    formatCompletedAt(date) {
      if (date == null) {
        return "-";
      }
      return moment.parseZone(date).format("lll");
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    returnDate(item) {
      return moment.parseZone(item).format("lll");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
