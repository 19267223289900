<template>
  <div>
    <v-app-bar app :hide-on-scroll="false" elevate-on-scroll v-resize="checkMobile" v-if="!isIos">
      <v-app-bar-nav-icon @click="reverseDrawer">
        <v-icon v-if="show_drawer">mdi-menu-open</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn text to="/bookings" @click="$store.commit('setNewBooking', false)" class="d-none d-lg-flex">
        <v-badge v-if="new_booking" dot color="red">
          <span class="">{{ $t("custom.bookings") }}</span>
        </v-badge>
        <span v-else class="">{{ $t("custom.bookings") }}</span>
      </v-btn>
      <v-menu offset-y open-on-hover v-if="user_role != 'partner' && user_role != 'agent'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
            {{ $t("custom.clients") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/clients">
            <v-list-item-title>{{ $t("custom.clients") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/debts">
            <v-list-item-title>{{ $t("custom.debts") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/fines">
            <v-list-item-title>{{ $t("custom.fines") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/invoices">
            <v-list-item-title>{{ $t("custom.invoices") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
            {{ $t("custom.calendar") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/calendars/timeline">
            <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/calendars/new">
            <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text to="/counts" class="d-none d-lg-flex" v-if="user_role != 'partner'">
        <span class="">{{ $t("custom.counts") }}</span>
      </v-btn>
      <v-btn v-if="user_role != 'agent'" text to="/cars" class="d-none d-lg-flex">
        <span class="">{{ $t("custom.cars") }}</span>
      </v-btn>
      <v-menu v-if="user_role != 'agent'" offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
            {{ $t("custom.service") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/service_tech">
            <v-list-item-title>{{ $t("custom.tech_service") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/service_insurance">
            <v-list-item-title>{{ $t("maintenance.insurances_and_taxes") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/service_payments">
            <v-list-item-title>{{ $t("maintenance.payments") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="user_role == 'superadmin' ||
        user_role == 'admin' ||
        user_role == 'manager' ||
        user_role == 'guest'
      " offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex">
            <span class="">
              <v-badge v-if="todos.length > 0" :color="checkLate()" :content="todos.length">
                {{ $t("custom.todo") }}
              </v-badge>
              <span v-else>
                {{ $t("custom.todo") }}
              </span>
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/todos_all">
            <v-list-item-title>{{ $t("header.all_todo") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/todos_user">
            <v-list-item-title>{{ $t("header.private_todo") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else-if="user_role != 'partner' && user_role != 'agent'" text class="d-none d-lg-flex mr-2"
        to="/todos_user">
        <span class="">
          <v-badge v-if="todos.length > 0" :color="checkLate()" :content="todos.length">
            {{ $t("custom.todo") }}
          </v-badge>
          <span v-else>
            {{ $t("custom.todo") }}
          </span>
        </span>
      </v-btn>
      <v-menu v-if="user_role == 'superadmin' ||
        user_role == 'admin' ||
        user_role == 'guest'
      " offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex">
            <span class="">
              {{ $t("header.company") }}
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/company_counts">
            <v-list-item-title>{{
              $t("header.company_counts")
              }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/admin/users">
            <v-list-item-title>{{ $t("custom.employers") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/analitics">
            <v-list-item-title>
              {{ $t("custom.analitics") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/contractors">
            <v-list-item-title>
              {{ $t("custom.contractors") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/agents">
            <v-list-item-title>
              {{ $t("agents.agents") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/company_profile">
            <v-list-item-title>{{
              $t("header.company_settings")
              }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider vertical class="d-none d-lg-flex mx-2"></v-divider>
      <v-btn v-if="user_role != 'agent'" text to="/transactions" class="">
        <span :class="$store.getters.getMainCompany &&
          $store.getters.getMainCompany.balance < 0
          ? 'red--text'
          : ''
          ">
          {{
            $store.getters.getMainCompany
              ? Math.round($store.getters.getMainCompany.balance)
              : 0
          }}
          <span>{{
            $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == "RUB"
              ? "₽"
              : "€"
          }}</span>
        </span>
      </v-btn>
      <!-- WhatsApp -->
      <v-btn
        v-if="!isMobile && !isNative && company && company.whatsapp && user_role != 'guest' && user_role != 'partner' && user_role != 'agent'"
        to="/whatsapp_chat" icon>
        <v-icon class="">
          mdi-whatsapp
        </v-icon>
      </v-btn>
      <v-btn v-if="!isMobile && !isNative" to="/search_free" icon>
        <v-icon class="">
          mdi-car-search-outline
        </v-icon>
      </v-btn>
      <!-- Messages -->
      <v-btn v-if="user_role != 'agent'" to="/messages" icon>
        <v-badge :content="messages_length" :value="messages_length" color="green" overlap>
          <v-icon> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
      <!-- Branches -->
      <v-menu v-if="user_role == 'superadmin' ||
        (currentUser() && currentUser().can_change_branch)
      " left bottom offset-y offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="loadBranches" v-bind="attrs" v-on="on" icon class="mr-2">
            <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
            <v-icon v-else> mdi-city-variant-outline </v-icon>
          </v-btn>
        </template>
        <v-list nav :dense="isMobile">
          <v-list-item v-for="branch in branches" :key="branch.id" @click="changeBranch(branch.id)"
            :active="branch.id == company_id">
            <v-list-item-title><v-icon v-if="branch.id == company_id" class="mr-1" color="primary">
                mdi-arrow-right-bold-circle-outline </v-icon>{{ branch.name }}, {{ branch.city }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!isNative && !isMobile" icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
        }/${$root.$i18n.locale}/helpcenter`" target="_blank" class="m-0">
        <v-icon> mdi-help-circle-outline </v-icon>
      </v-btn>
      <v-menu left bottom offset-y offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex mr-2">
            {{ currentUser().name ? currentUser().name : currentUser().email }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/profile">
            <v-list-item-title>{{ $t("header.profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/salary" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/check_lists" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.check_lists") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/schedule_calendar" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="($store.getters.getMainCompany && $store.getters.getCompany.company && $store.getters.getCompany.company.in_booking)
            && user_role != 'agent'
          " to="/agregator/settings">
            <v-list-item-title>{{ $t("agregator.agregator") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/investors" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.partners") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/branches" v-if="user_role == 'superadmin'">
            <v-list-item-title>{{ $t("header.branches") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tariffs" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'manager' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.tariffs") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/doc_template" v-if="($store.getters.getMainCompany &&
            $store.getters.getCompany.company &&
            $store.getters.getCompany.company.new_templates &&
            user_role == 'superadmin') ||
            user_role == 'admin' ||
            user_role == 'manager' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.templates") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/store" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToMap()" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("header.mayaks") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/transactions" v-if="user_role != 'partner' && user_role != 'agent' && !isNative">
            <v-list-item-title>{{ $t("header.balance") }}:
              {{
                $store.getters.getMainCompany
                  ? Math.round($store.getters.getMainCompany.balance)
                  : 0
              }}<span>{{
                $store.getters.getMainCompany &&
                  $store.getters.getMainCompany.currency == "RUB"
                  ? "₽"
                  : "€"
              }}</span></v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isIos" :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/helpcenter`" target="_blank">
            <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut()">
            <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu left bottom offset-y offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" id="registration-step-1">
            <span class="mr-1">
              <v-avatar v-on="on" size="45px" color="blue">
                <v-img v-if="!isNative && currentUser() && currentUser().avatar_url" :src="currentUser().avatar_url"
                  :alt="currentUser().name" class="grey lighten-2">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-gravatar v-else-if="!isNative" crossorigin="Anonymous" :email="currentUser().email" />
                <span v-else class="white--text text-h5">{{
                  currentUser() && currentUser().name && currentUser().name[0]
                    ? currentUser().name[0]
                    : currentUser().email[0]
                }}</span>
              </v-avatar>
            </span>
          </v-btn>
        </template>
        <v-list :dense="isMobile || isNative">
          <v-list-item to="/profile">
            <v-list-item-title>{{ $t("header.profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/salary" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/check_lists" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.check_lists") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/schedule_calendar" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="($store.getters.getMainCompany && $store.getters.getCompany.company && $store.getters.getCompany.company.in_booking) && user_role != 'agent'"
            to="/agregator/settings">
            <v-list-item-title>{{ $t("agregator.agregator") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/investors" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.partners") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/branches" v-if="user_role == 'superadmin'">
            <v-list-item-title>{{ $t("header.branches") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tariffs" v-if="user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'manager' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.tariffs") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/doc_template" v-if="($store.getters.getMainCompany &&
            $store.getters.getCompany.company &&
            $store.getters.getCompany.company.new_templates &&
            user_role == 'superadmin') ||
            user_role == 'admin' ||
            user_role == 'manager' ||
            user_role == 'guest'
          ">
            <v-list-item-title>{{ $t("header.templates") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/store" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goToMap()" v-if="user_role != 'partner' && user_role != 'agent'">
            <v-list-item-title>{{ $t("header.mayaks") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/transactions" v-if="user_role != 'partner' && user_role != 'agent' && !isNative">
            <v-list-item-title>{{ $t("header.balance") }}:
              {{
                $store.getters.getMainCompany
                  ? Math.round($store.getters.getMainCompany.balance)
                  : 0
              }}<span>{{
                $store.getters.getMainCompany &&
                  $store.getters.getMainCompany.currency == "RUB"
                  ? "₽"
                  : "€"
              }}</span></v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isIos" :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/${$root.$i18n.locale}/helpcenter`" target="_blank">
            <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut()">
            <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog :retain-focus="false" @click:outside="(showMessageDialog = false), (message = null)"
        v-model="showMessageDialog" max-width="500px">
        <v-card v-if="message">
          <v-card-title>
            <h2>{{ message.title }}</h2>
            <v-spacer></v-spacer>
            <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
              @click="(showMessageDialog = false), (message = null)">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            {{ message.sender }}
          </v-card-subtitle>
          <v-card-text>
            <div v-html="message.text"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <audio hidden="true" ref="audio">
        <source src="/discord.mp3" type="audio/mpeg" />
      </audio>
      <audio hidden="true" ref="message">
        <source src="/message.mp3" type="audio/mpeg" />
      </audio>
    </v-app-bar>
    <v-app-bar app :hide-on-scroll="false" v-resize="checkMobile" :prominent="isNative" height="110" v-else>
      <!-- <v-toolbar-title v-if="isNative || isMobile" class="mr-2">
        <router-link to="/dashboard" class="ml-0">
          <v-avatar size="28">
            <img
              src="/img/logo-100x100.png"
              alt="RentProg"
            >
          </v-avatar>
        </router-link>
      </v-toolbar-title> -->
      <v-flex md12 row class="mt-12 d-flex align-middle">
        <!-- <router-link v-if="user_role != 'agent'" to="/dashboard" class="ml-2 mr-1 mt-1">
          <v-avatar size="28">
            <img
              src="/img/logo-100x100.png"
              alt="RentProg"
            >
          </v-avatar>
        </router-link>
        <router-link v-else to="/search_free" class="ml-2 mr-1 mt-1">
          <v-avatar size="28">
            <img
              src="/img/logo-100x100.png"
              alt="RentProg"
            >
          </v-avatar>
        </router-link> -->
        <v-app-bar-nav-icon @click="reverseDrawer">
          <v-icon v-if="show_drawer">mdi-menu-open</v-icon>
          <v-icon v-else>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <!-- <v-menu
          close-on-click
          :close-on-content-click="closeOnContentClick"
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon
              v-bind="attrs"
              v-on="on"
              @click="closeOnContentClick = false"
            ></v-app-bar-nav-icon>
          </template>

          <v-list
            v-if="
              user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            "
            dense
            class="pt-0 overflow-y-auto"
            :style="
              isMobile || isNative ? 'max-height: 620px' : 'max-height: 700px'
            "
          >
            <v-list-item to="/dashboard" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bookings" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/calendars/timeline"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-cursor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/booking/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-plus-box</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.add_booking")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/search_free" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-car-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.search_free")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-for="item in items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="closeOnContentClick = true"
                style="padding-left: 40px !important"
                :to="subItem.to"
              >
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner' && user_role != 'agent'" to="/whatsapp_chat">
              <v-list-item-action>
                <v-icon>mdi-whatsapp</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>WhatsApp</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="user_role != 'agent'"
              to="/transactions"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon color="success">mdi-currency-usd</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :class="
                      $store.getters.getMainCompany &&
                      $store.getters.getMainCompany.balance < 0
                        ? 'red--text'
                        : ''
                    "
                  >
                    {{ $t("header.balance") }}:
                    {{
                      $store.getters.getMainCompany
                        ? Math.round($store.getters.getMainCompany.balance)
                        : 0
                    }}
                    <span>{{
                      $store.getters.getMainCompany &&
                      $store.getters.getMainCompany.currency == "RUB"
                        ? "₽"
                        : "€"
                    }}</span>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!isIos"
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`"
              target="_blank"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-action>
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else-if="user_role == 'manager'"
            dense
            class="pt-0 overflow-y-auto"
            :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'"
          >
            <v-list-item to="/dashboard" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bookings" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/calendars/timeline"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-cursor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/booking/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-plus-box</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.add_booking")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/search_free" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-car-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.search_free")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-for="item in manager_items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="closeOnContentClick = true"
                style="padding-left: 40px !important"
                :to="subItem.to"
              >
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item to="/store" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-store-cog</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/todos_user" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-multiple-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.todo") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/schedule_calendar"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-multiselect</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/salary" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/admin/users" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-account-hard-hat</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.employers")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'" to="/whatsapp_chat">
              <v-list-item-action>
                <v-icon>mdi-whatsapp</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>WhatsApp</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!isIos"
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`"
              target="_blank"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-action>
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else-if="user_role == 'partner'"
            dense
            class="pt-0 overflow-y-auto"
            :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'"
          >
            <v-list-item to="/dashboard" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bookings" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/calendars/timeline"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-cursor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-for="item in partners_items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="closeOnContentClick = true"
                style="padding-left: 40px !important"
                :to="subItem.to"
              >
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-if="!isIos"
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`"
              target="_blank"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-action>
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else-if="user_role == 'agent'"
            dense
            class="pt-0 overflow-y-auto"
            :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'"
          >
            <v-list-item to="/search_free" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/booking/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-plus-box</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.add_booking")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/search_free" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-car-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.search_free")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bookings" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!isIos"
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`"
              target="_blank"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-action>
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            v-else
            dense
            class="pt-0 overflow-y-auto"
            :style="isMobile ? 'max-height: 500px' : 'max-height: 1000px'"
          >
            <v-list-item to="/dashboard" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.main") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/bookings" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-book-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.bookings") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/calendars/timeline"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-cursor</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-month-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/search_free" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-car-search-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.search_free")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-for="item in worker_items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="closeOnContentClick = true"
                style="padding-left: 40px !important"
                :to="subItem.to"
              >
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item to="/store" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-store-cog</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/todos_user" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-calendar-multiple-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>ToDo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/schedule_calendar"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-calendar-multiselect</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/salary" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/booking/new" @click="closeOnContentClick = true">
              <v-list-item-action>
                <v-icon>mdi-plus-box</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("custom.add_booking")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!isIos"
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`"
              target="_blank"
              @click="closeOnContentClick = true"
            >
              <v-list-item-action>
                <v-icon>mdi-help-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-action>
                <v-icon color="error">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-toolbar-title v-if="!isNative && !isMobile">
          <router-link to="/dashboard" :class="$vuetify.theme.dark ? 'white--text text-decoration-none' : 'black--text text-decoration-none'">
            <span class="font-weight-light text-decoration-none">RentProg</span>
          </router-link>
        </v-toolbar-title> -->
        <v-spacer></v-spacer>
        <v-btn text to="/bookings" @click="$store.commit('setNewBooking', false)" class="d-none d-lg-flex">
          <v-badge v-if="new_booking" dot color="red">
            <span class="">{{ $t("custom.bookings") }}</span>
          </v-badge>
          <span v-else class="">{{ $t("custom.bookings") }}</span>
        </v-btn>
        <v-menu offset-y open-on-hover v-if="user_role != 'partner'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
              {{ $t("custom.clients") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/clients">
              <v-list-item-title>{{ $t("custom.clients") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/debts">
              <v-list-item-title>{{ $t("custom.debts") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/fines">
              <v-list-item-title>{{ $t("custom.fines") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/invoices">
              <v-list-item-title>{{ $t("custom.invoices") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
              {{ $t("custom.calendar") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/calendars/timeline">
              <v-list-item-title>{{ $t("custom.day_plan") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/calendars/new">
              <v-list-item-title>{{ $t("custom.calendar") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text to="/counts" class="d-none d-lg-flex" v-if="user_role != 'partner'">
          <span class="">{{ $t("custom.counts") }}</span>
        </v-btn>
        <v-btn text to="/cars" class="d-none d-lg-flex">
          <span class="">{{ $t("custom.cars") }}</span>
        </v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="d-none d-lg-flex" v-bind="attrs" v-on="on">
              {{ $t("custom.service") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/service_tech">
              <v-list-item-title>{{
                $t("custom.tech_service")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/service_insurance">
              <v-list-item-title>{{ $t("maintenance.insurances_and_taxes") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/service_payments">
              <v-list-item-title>{{ $t("maintenance.payments") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu v-if="user_role == 'superadmin' ||
          user_role == 'admin' ||
          user_role == 'manager' ||
          user_role == 'guest'
        " offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex">
              <span class="">
                <v-badge v-if="todos.length > 0" :color="checkLate()" :content="todos.length">
                  {{ $t("custom.todo") }}
                </v-badge>
                <span v-else>
                  {{ $t("custom.todo") }}
                </span>
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/todos_all">
              <v-list-item-title>{{ $t("header.all_todo") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/todos_user">
              <v-list-item-title>{{
                $t("header.private_todo")
                }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else-if="user_role != 'partner'" text class="d-none d-lg-flex mr-2" to="/todos_user">
          <span class="">
            <v-badge v-if="todos.length > 0" :color="checkLate()" :content="todos.length">
              {{ $t("custom.todo") }}
            </v-badge>
            <span v-else>
              {{ $t("custom.todo") }}
            </span>
          </span>
        </v-btn>
        <v-menu v-if="user_role == 'superadmin' ||
          user_role == 'admin' ||
          user_role == 'guest'
        " offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex">
              <span class="">
                {{ $t("header.company") }}
              </span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/company_counts">
              <v-list-item-title>{{
                $t("header.company_counts")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/users">
              <v-list-item-title>{{ $t("custom.employers") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/analitics">
              <v-list-item-title>
                {{ $t("custom.analitics") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/contractors">
              <v-list-item-title>
                {{ $t("custom.contractors") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/company_profile">
              <v-list-item-title>{{
                $t("header.company_settings")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/agents">
              <v-list-item-title>
                {{ $t("agents.agents") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-if="!isNative && !isMobile" icon :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/${$root.$i18n.locale}/helpcenter`" target="_blank" class="m-0">
          <v-icon> mdi-help-circle-outline </v-icon>
        </v-btn>
        <v-divider vertical class="d-none d-lg-flex mr-3"></v-divider>
        <v-btn small text to="/transactions" class="my-auto">
          <span :class="$store.getters.getMainCompany &&
            $store.getters.getMainCompany.balance < 0
            ? 'red--text'
            : ''
            ">
            {{
              $store.getters.getMainCompany
                ? Math.round($store.getters.getMainCompany.balance)
                : 0
            }}
            <span>{{
              $store.getters.getMainCompany &&
                $store.getters.getMainCompany.currency == "RUB"
                ? "₽"
                : "€"
            }}</span>
          </span>
        </v-btn>
        <!-- WhatsApp -->
        <!-- <v-btn v-if="company && company.whatsapp && user_role != 'guest' && user_role != 'partner'" to="/whatsapp_chat" icon>
          <v-icon class="">
            mdi-whatsapp
          </v-icon>
        </v-btn> -->
        <!-- Messages -->
        <v-btn to="/messages" icon>
          <v-badge :content="messages_length" :value="messages_length" color="green" overlap>
            <v-icon> mdi-bell </v-icon>
          </v-badge>
        </v-btn>
        <!-- Branches -->
        <v-menu v-if="user_role == 'superadmin' ||
          (currentUser() && currentUser().can_change_branch)
        " left bottom offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="loadBranches" v-bind="attrs" v-on="on" icon class="mr-2">
              <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
              <v-icon v-else> mdi-city-variant-outline </v-icon>
            </v-btn>
          </template>
          <v-list nav :dense="isMobile">
            <v-list-item v-for="branch in branches" :key="branch.id" @click="changeBranch(branch.id)"
              :active="branch.id == company_id">
              <v-list-item-title><v-icon v-if="branch.id == company_id" class="mr-1" color="primary">
                  mdi-arrow-right-bold-circle-outline </v-icon>{{ branch.name }}, {{ branch.city }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Name -->
        <v-menu left bottom offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="d-none d-lg-flex mr-2">
              {{ currentUser().name ? currentUser().name : currentUser().email }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item to="/profile">
              <v-list-item-title>{{ $t("header.profile") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/salary" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/check_lists" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{
                $t("header.check_lists")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/schedule_calendar" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/company_counts" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{
                $t("header.company_counts")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/company_profile" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{
                $t("header.company_settings")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="($store.getters.getMainCompany && $store.getters.getCompany.company && $store.getters.getCompany.company.in_booking)
            " to="/agregator/settings">
              <v-list-item-title>{{
                $t("agregator.agregator")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/investors" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.partners") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/branches" v-if="user_role == 'superadmin'">
              <v-list-item-title>{{ $t("header.branches") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/tariffs" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'manager' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.tariffs") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/doc_template" v-if="($store.getters.getMainCompany &&
              $store.getters.getCompany.company &&
              $store.getters.getCompany.company.new_templates &&
              user_role == 'superadmin') ||
              user_role == 'admin' ||
              user_role == 'manager' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.templates") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/store" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToMap()" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("header.mayaks") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/transactions" v-if="user_role != 'partner' && !isNative">
              <v-list-item-title>{{ $t("header.balance") }}:
                {{
                  $store.getters.getMainCompany
                    ? Math.round($store.getters.getMainCompany.balance)
                    : 0
                }}<span>{{
                  $store.getters.getMainCompany &&
                    $store.getters.getMainCompany.currency == "RUB"
                    ? "₽"
                    : "€"
                }}</span></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isIos" :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`" target="_blank">
              <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- Avatar -->
        <v-menu left bottom offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" id="registration-step-1">
              <span class="mr-1">
                <v-avatar v-on="on" size="45px" color="blue">
                  <v-img v-if="!isNative && currentUser() && currentUser().user_avatar && currentUser().user_avatar.url"
                    :src="currentUser().user_avatar.url" :alt="currentUser().name" class="grey lighten-2"></v-img>
                  <v-gravatar v-else-if="!isNative" crossorigin="Anonymous" :email="currentUser().email" />
                  <span v-else class="white--text text-h5">{{
                    currentUser() && currentUser().name && currentUser().name[0]
                      ? currentUser().name[0]
                      : currentUser().email[0]
                  }}</span>
                </v-avatar>
              </span>
            </v-btn>
          </template>
          <v-list :dense="isMobile || isNative">
            <v-list-item to="/profile">
              <v-list-item-title>{{ $t("header.profile") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/salary" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("custom.salary") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/check_lists" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{
                $t("header.check_lists")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/schedule_calendar" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("custom.schedule") }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="($store.getters.getMainCompany && $store.getters.getCompany.company && $store.getters.getCompany.company.in_booking)
            " to="/agregator/settings">
              <v-list-item-title>{{
                $t("agregator.agregator")
                }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/investors" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.partners") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/branches" v-if="user_role == 'superadmin'">
              <v-list-item-title>{{ $t("header.branches") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/tariffs" v-if="user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'manager' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.tariffs") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/doc_template" v-if="($store.getters.getMainCompany &&
              $store.getters.getCompany.company &&
              $store.getters.getCompany.company.new_templates &&
              user_role == 'superadmin') ||
              user_role == 'admin' ||
              user_role == 'manager' ||
              user_role == 'guest'
            ">
              <v-list-item-title>{{ $t("header.templates") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/store" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("store.store") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToMap()" v-if="user_role != 'partner'">
              <v-list-item-title>{{ $t("header.mayaks") }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/transactions" v-if="user_role != 'partner' && !isNative">
              <v-list-item-title>{{ $t("header.balance") }}:
                {{
                  $store.getters.getMainCompany
                    ? Math.round($store.getters.getMainCompany.balance)
                    : 0
                }}<span>{{
                  $store.getters.getMainCompany &&
                    $store.getters.getMainCompany.currency == "RUB"
                    ? "₽"
                    : "€"
                }}</span></v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!isIos" :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/${$root.$i18n.locale}/helpcenter`" target="_blank">
              <v-list-item-title>{{ $t("custom.help") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-title>{{ $t("header.sign_out") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
      <v-dialog :retain-focus="false" @click:outside="(showMessageDialog = false), (message = null)"
        v-model="showMessageDialog" max-width="500px">
        <v-card v-if="message">
          <v-card-title>
            <h2>{{ message.title }}</h2>
            <v-spacer></v-spacer>
            <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
              @click="(showMessageDialog = false), (message = null)">
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            {{ message.sender }}
          </v-card-subtitle>
          <v-card-text>
            <div v-html="message.text"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <audio hidden="true" ref="audio">
        <source src="/discord.mp3" type="audio/mpeg" />
      </audio>
      <audio hidden="true" ref="message">
        <source src="/message.mp3" type="audio/mpeg" />
      </audio>
    </v-app-bar>
    <drawer></drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Gravatar from "vue-gravatar";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from '@capacitor/app';
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { Badge } from "@capawesome/capacitor-badge";
import { StatusBar } from "@capacitor/status-bar";
import drawer from "./Drawer";

export default {
  name: "AppHeader",
  data() {
    return {
      items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.company_counts"),
              icon: "mdi-cash-lock",
              to: "/company_counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
        {
          icon: "mdi-calendar-multiple-check",
          title: this.$t("custom.todo"),
          active: false,
          items: [
            {
              title: this.$t("header.all_todo"),
              icon: "mdi-calendar-multiple-check",
              to: "/todos_all",
            },
            {
              title: this.$t("header.private_todo"),
              icon: "mdi-calendar-account-outline",
              to: "/todos_user",
            },
          ],
        },
        {
          icon: "mdi-domain",
          title: this.$t("header.company"),
          active: false,
          items: [
            {
              title: this.$t("custom.analitics"),
              icon: "mdi-chart-areaspline",
              to: "/analitics",
            },
            {
              title: this.$t("custom.employers"),
              icon: "mdi-account-hard-hat",
              to: "/admin/users",
            },
            {
              title: this.$t("custom.schedule"),
              icon: "mdi-calendar-multiselect",
              to: "/schedule_calendar",
            },
            {
              title: this.$t("custom.salary"),
              icon: "mdi-account-cash-outline",
              to: "/salary",
            },
            {
              title: this.$t("header.partners"),
              icon: "mdi-handshake-outline",
              to: "/investors",
            },
            {
              title: this.$t("header.branches"),
              icon: "mdi-source-branch",
              to: "/branches",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
            {
              title: this.$t("header.company_settings"),
              icon: "mdi-cog-box",
              to: "/company_profile",
            },
            {
              title: this.$t("agents.agents"),
              icon: "mdi-face-agent",
              to: "/agents",
            },
          ],
        },
      ],
      selected_items: [],
      worker_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      manager_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
            {
              title: this.$t("custom.add_car"),
              icon: "mdi-plus-box",
              to: "/cars/new",
            },
          ],
        },
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.clients"),
              icon: "mdi-calendar-account-outline",
              to: "/clients",
            },
            {
              title: this.$t("custom.fines"),
              icon: "mdi-car-emergency",
              to: "/fines",
            },
            {
              title: this.$t("custom.debts"),
              icon: "mdi-credit-card-remove-outline",
              to: "/debts",
            },
            {
              title: this.$t("custom.invoices"),
              icon: "mdi-receipt",
              to: "/invoices",
            },
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
            {
              title: this.$t("custom.contractors"),
              icon: "mdi-account-convert",
              to: "/contractors",
            },
          ],
        },
        {
          icon: "mdi-calendar-multiple-check",
          title: this.$t("custom.todo"),
          active: false,
          items: [
            {
              title: this.$t("header.all_todo"),
              icon: "mdi-calendar-multiple-check",
              to: "/todos_all",
            },
            {
              title: this.$t("header.private_todo"),
              icon: "mdi-calendar-account-outline",
              to: "/todos_user",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      partners_items: [
        {
          icon: "mdi-car-multiple",
          title: this.$t("custom.cars"),
          active: false,
          items: [
            {
              title: this.$t("custom.cars"),
              icon: "mdi-car-multiple",
              to: "/cars",
            },
          ],
        },
        {
          icon: "mdi-book-plus-multiple",
          title: this.$t("custom.service"),
          active: false,
          items: [
            {
              title: this.$t("custom.tech_service"),
              icon: "mdi-car-cog",
              to: "/service_tech",
            },
            {
              title: this.$t("maintenance.insurances_and_taxes"),
              icon: "mdi-shield-car",
              to: "/service_insurance",
            },
            {
              title: this.$t("maintenance.payments"),
              icon: "mdi-cash-sync",
              to: "/service_payments",
            },
          ],
        },
      ],
      agents_items: [
        {
          icon: "mdi-calendar-account-outline",
          title: this.$t("custom.clients"),
          active: false,
          items: [
            {
              title: this.$t("custom.add_client"),
              icon: "mdi-plus-box",
              to: "/client/new",
            },
          ],
        },
        {
          icon: "mdi-cash-register",
          title: this.$t("custom.counts"),
          active: false,
          items: [
            {
              title: this.$t("custom.counts"),
              icon: "mdi-cash-register",
              to: "/counts",
            },
            {
              title: this.$t("header.add_payment"),
              icon: "mdi-book-plus-multiple",
              to: "/counts/new",
            },
          ],
        },
      ],
      branches: [],
      messages_size: 0,
      messages: [],
      message: null,
      closeOnContentClick: false,
      user: [],
      todos: [],
      showMessageDialog: false,
      servicesWarning: false,
      check_server: null,
      isLoading: false,
      isMobile: false,
      error: "",
    };
  },
  mounted() {
    let self = this;
    if (this.$store.getters.getCompany.company) {
      this.$cable.subscribe({
        channel: "AppearanceChannel",
        room: this.$store.getters.getCompany.company.id,
        user_id: this.$store.getters.currentUserId,
      });
      this.$cable.subscribe({
        channel: "MessageChannel",
        room: this.$store.getters.currentUserId,
        user_id: this.$store.getters.currentUserId,
      });
      this.$cable.subscribe({
        channel: "NewBookingChannel",
        room: this.$store.getters.getCompany.company.id,
      });
      this.$cable.subscribe({
        channel: "MainCompanyChannel",
        room: this.$store.getters.getMainCompany.id,
      });
      this.$cable.subscribe({
        channel: "CompanyChannel",
        room: this.$store.getters.getCompany.company.id,
      });
      console.info(
        "Company " +
        this.$store.getters.getCompany.company.id +
        " MainCompany " +
        this.$store.getters.getMainCompany.id
      );
    } else {
      // не удалять, нужно
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          // this.$router.push({ path: `/dashboard` }).catch(err => {})
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.company_data"));
          console.log(error);
        });
    }
    if (
      (this.isPushNotificationsAvailable &&
        Capacitor.isNativePlatform() &&
        Capacitor.getPlatform().toLowerCase() == "ios") ||
      Capacitor.getPlatform().toLowerCase() == "android"
    ) {
      const pushRegistration = async () => {
        await PushNotifications.addListener("registration", (token) => {
          FCM.getToken()
            .then((r) => {
              self.fcm_token = r.token;
              if (r.token) {
                self.axios
                  .post(
                    "/api/v1/device_infos",
                    {
                      device_infos: {
                        device_type: Capacitor.getPlatform().toLowerCase(),
                        token: r.token,
                      },
                    },
                    {
                      headers: {
                        Authorization: self.$store.getters.getAuthToken,
                      },
                    }
                  )
                  .catch((error) => {
                    self.setError(error, self.$t("errors.error"));
                  });
              }
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.error"));
            });
        });
      };

      const pushRegistrationError = async () => {
        await PushNotifications.addListener("registrationError", (error) => {
          self.setError(JSON.stringify(error), "Error on registration");
        });
      };
      const pushNotificationReceived = async () => {
        await PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push received: ", notification);
            const increase = async () => {
              await Badge.increase();
            };
            increase();
          }
        );
      };

      const pushNotificationActionPerformed = async () => {
        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            console.log("Push action performed: ", notification);
            const clear = async () => {
              await Badge.clear();
            };
            clear();
          }
        );
      };

      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // self.push_granted = true;
          pushRegistration();
          pushRegistrationError();
          pushNotificationReceived();
          pushNotificationActionPerformed();
        } else {
          // Show some error
        }
        console.log("Push notifications permissions: ", result);
      });
    }
  },
  beforeCreate() {
    if (!(process.env.NODE_ENV === "development") || !this.isNative) {
      this.$OneSignal.showSlidedownPrompt();
    }
  },
  destroy() {
    let result = this.$cable.unsubscribe({
      channel: "AppearanceChannel",
      room: this.$store.getters.getCompany.company.id,
      user_id: this.$store.getters.currentUserId,
    });
    this.$cable.unsubscribe({
      channel: "MessageChannel",
      room: this.$store.getters.currentUserId,
      user_id: this.$store.getters.currentUserId,
    });
    this.$cable.unsubscribe({
      channel: "NewBookingChannel",
      room: this.$store.getters.getCompany.company.id,
    });
    this.$cable.unsubscribe({
      channel: "MainCompanyChannel",
      room: this.$store.getters.getMainCompany.id,
    });
  },
  created() {
    let self = this;
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.getMainCompany &&
      this.$store.getters.getMainCompany.payer_state == 3
    ) {
      this.$swal({
        showConfirmButton: true,
        icon: "warning",
        title: this.$t("errors.no_money_title"),
        text: this.$t("errors.no_money_text"),
      });
    }

    if (
      this.$store.getters.getUserSettings &&
      this.$store.getters.getUserSettings.dark_theme
    ) {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }

    // if (
    //   this.$store.getters.getCompany.company &&
    //   this.$store.getters.getCurrentUser.email &&
    //   this.isNative &&
    //   $crisp.is("website:available")
    // ) {
    //   const hideStatusBar = async () => {
    //     await StatusBar.hide();
    //   };
    //   $crisp.push([
    //     "on",
    //     "chat:opened",
    //     () => {
    //       hideStatusBar();
    //     },
    //   ]);

    //   // $crisp.do("chat:hide");
    //   // $crisp.push(["do", "chat:hide", [true]]);
    // }
    console.log("typeof $crisp !== 'undefined'", typeof $crisp !== "undefined");
    // Добавляю в чат данные пользователя
    setTimeout(() => {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCurrentUser.email &&
        this.isNative &&
        $crisp && $crisp.push && typeof $crisp.push === 'function' && $crisp.on && typeof $crisp.on === 'function' &&
        this.checkCrispSiteAvailable()
      ) {
        // set CRISP_TOKEN_ID
        $crisp.push(["safe", true]);
        const hideStatusBar = async () => {
          await StatusBar.hide();
        };
        const showStatusBar = async () => {
          await StatusBar.show();
        };
        $crisp.on("chat:opened", () => {
          console.log("chat:opened");
          hideStatusBar();
        });
        $crisp.on("chat:closed", () => {
          console.log("chat:closed");
          showStatusBar();
        });
      }
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCurrentUser.email &&
        typeof $crisp !== "undefined" && $crisp &&
        this.checkCrispSiteAvailable()
      ) {
        $crisp.push([
          "set",
          "user:email",
          [this.$store.getters.getCurrentUser.email],
        ]);
      }
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.name &&
        typeof $crisp !== "undefined" && $crisp &&
        this.checkCrispSiteAvailable()
      ) {
        $crisp.push(["safe", true]);
        $crisp.push([
          "set",
          "user:company",
          [this.$store.getters.getCompany.company.name],
        ]);
      }
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCurrentUser.phone &&
        typeof $crisp !== "undefined" && $crisp &&
        this.checkCrispSiteAvailable()
      ) {
        $crisp.push(["safe", true]);
        $crisp.push([
          "set",
          "user:phone",
          [this.$store.getters.getCurrentUser.phone],
        ]);
      }
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCurrentUser.name &&
        typeof $crisp !== "undefined" && $crisp &&
        this.checkCrispSiteAvailable()
      ) {
        $crisp.push(["safe", true]);
        $crisp.push([
          "set",
          "user:nickname",
          [
            this.$store.getters.getCurrentUser.name +
            " " +
            this.$store.getters.getCompany.company.name +
            " " +
            this.$store.getters.getCurrentUser.role +
            " " +
            this.$store.getters.getMainCompany.payer_state,
          ],
        ]);
      }
      // if (
      //   this.$store.getters.getCompany.company &&
      //   this.$store.getters.getCurrentUser.email &&
      //   this.isNative
      // ) {
      //   $crisp.do("chat:hide");
      //   // $crisp.push(["do", "chat:hide", [true]]);
      // }
    }, 3000);
    if (this.isNative) {
      console.info(
        `App version ${this.$store.getters.appVersion
        } | ${Capacitor.getPlatform()}`
      );
    } else {
      console.info(
        `App version ${this.$store.getters.appVersion} | ${process.env.PACKAGE_VERSION}`
      );
    }
    if (this.isAndroid) {
      CapacitorApp.addListener('backButton', () => {
        console.log('Back button clicked');
        this.$router.go(-1);
      });
    }
    if (this.isNative) {
      CapacitorApp.addListener('appUrlOpen', (data) => {
        console.log('App opened with URL: ' + data.url);
      });
      CapacitorApp.addListener('appStateChange', ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
        if (isActive) {
          console.log('App is active');
        } else {
          console.log('App is inactive');
        }
      });
    }
  },
  channels: {
    AppearanceChannel: {
      connected() {
        // console.log("Connected to ActionCable");
        // this.$store.dispatch('appearance_user', 'content');
        this.appearance_user();
      },
      // rejected() {
      //   console.log("rejected");
      // },
      received(data) {
        // console.log("received data to ActionCable", data);
        this.$store.commit("setLoginUsers", data);

        // Выходим в соседней вкладке
        if (
          this.$store.getters.currentUserId &&
          this.$store.getters.currentUserId == data.id &&
          data.login == false &&
          data.logout == true
        ) {
          // console.log("Logout", data);
          // this.$cable.unsubscribe("AppearanceChannel");
          this.signOut();
        }
      },
      disconnected() {
        console.log("disconnected");
      }
    },
    MessageChannel: {
      received(data) {
        // Add new flag
        data.new = true;
        this.$store.commit("addMessage", data);
        if (
          data.sender &&
          data.sender != "Host" &&
          this.$store.getters.getCompany &&
          this.$store.getters.getCompany.company &&
          this.$store.getters.getCompany.company.new_message_sound &&
          this.$store.getters.getUserSettings &&
          this.$store.getters.getUserSettings.new_message_sound &&
          !this.isNative &&
          this.$refs["message"]
        ) {
          this.$refs["message"].play();
        }
      },
    },
    NewBookingChannel: {
      received(data) {
        this.$store.commit("setNewBooking", true);
        if (
          this.$store.getters.getCompany &&
          this.$store.getters.getCompany.company &&
          this.$store.getters.getCompany.company.new_booking_sound &&
          this.$store.getters.getUserSettings &&
          this.$store.getters.getUserSettings.new_booking_sound &&
          !this.isNative &&
          this.$refs["audio"]
        ) {
          this.$refs["audio"].play();
        }
        // var audio = new Audio(
        //   process.env.NODE_ENV === "development"
        //     ? "http://localhost:8080/discord.mp3"
        //     : "https://web.rentprog.ru/discord.mp3"
        // ); // path to file
        // audio.play();
      },
    },
    MainCompanyChannel: {
      received(data) {
        this.$store.commit("setMainCompany", data.main_company);
        // console.log("MainCompanyChannel", data.main_company);
      },
    },
    CompanyChannel: {
      // connected() {
      //   console.log("Connected to CompanyChannel");
      // },
      received(data) {
        console.log("received data from CompanyChannel", data);
        this.$store.commit("setCompany", { company: data.company });
      },
    },
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
    getMessages() {
      return this.$store.getters.getMessages;
    },
    messages_length() {
      // Отображаем количество только sender != Host
      if (
        this.$store.getters.getMessages &&
        Array.isArray(this.$store.getters.getMessages) &&
        this.$store.getters.getMessages.length > 0
      ) {
        let filtered = this.$store.getters.getMessages.filter(
          (message) => message.sender != "Host"
        );
        return filtered.length;
      } else {
        return 0;
      }
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    new_booking() {
      return this.$store.getters.getNewBookingState;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    isPushNotificationsAvailable() {
      return Capacitor.isPluginAvailable("PushNotifications");
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    isAndroid() {
      return Capacitor.getPlatform().toLowerCase() == "android" ? true : false;
    },
    company_id() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company
      ) {
        return this.$store.getters.getCompany.company.id;
      } else {
        return null;
      }
    },
    company() {
      if (this.$store.getters.getCompany && this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
    show_drawer: {
      get: function () {
        return this.$store.getters.getDrawer
      },
      set: function (newValue) {
        this.$store.commit("setDrawer", newValue)
      }
    },
  },
  methods: {
    appearance_user: function () {
      if (this.$store.state.signedIn) {
        if (this.$store.getters.getCompany.company) {
          this.$cable.perform({
            channel: "AppearanceChannel",
            action: "appearance_user",
            room: this.$store.getters.getCompany.company.id,
            data: {
              content: this.$store.getters.getCurrentUser,
            },
          });
        } else {
          this.axios
            .get(`/api/v1/company`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.$store.commit("setCompany", { company: response.data });
              // this.$router.push({ path: `/dashboard` }).catch(err => {})
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.company_data"));
              console.log(error);
            });
        }
      }
    },
    createdBefore(date) {
      if (date) {
        return moment(date).format("ll");
      }
    },
    readMessage(message_id) {
      this.$cable.perform({
        channel: "MessageChannel",
        action: "read_message",
        user_id: this.$store.getters.currentUserId,
        room: this.$store.getters.currentUserId,
        data: {
          content: message_id,
        },
      });
    },
    loadBranches() {
      if (this.branches && this.branches.length > 0) {
        return;
      }
      this.isLoading = true;
      this.axios
        .get("/api/v1/main_companies", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.branches = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.branch_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      // this.$router.push({ path: `/dashboard` }).catch((err) => {});
    },
    changeBranch(id) {
      if (this.$store.getters.getCompany.company.id == id) {
        return;
      }
      let self = this;
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/select_company",
          {
            id: id,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get("/me.json", {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.$store.commit("setCurrentUser", response.data);
            })
            .catch(function (error) {
              self.$store.commit("unsetCurrentUser");
              self.isLoading = false;
              self.$swal({
                title: self.$t("errors.error"),
                text: error,
                icon: "error",
              });
              // self.$router.push({ path: `/signin` }).catch(err => {});
              console.log(error);
            });
          if (this.$router.currentRoute.path == "/dashboard") {
            // Переход на главную страницу и обновление
            setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
            }, 500);
          } else {
            if (this.user_role != 'agent') {
              this.$router.push({ path: `/dashboard` }).catch((err) => { });
            } else {
              this.$router.push({ path: `/search_free` }).catch((err) => { });
            }
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.change_branch"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: this.$t("errors.change_branch"),
            text: this.error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    goToMap() {
      if (this.main_company && this.main_company.beacons_on && !this.isMobile && !this.isNative) {
        let self = this
        let data = new URLSearchParams({
          "email": this.$store.getters.getCurrentUser.email,
          "password": this.$store.getters.getCurrentUser.traccar_password
        })
        // authorization in socket
        let session_url = "https://vos-rentprog.ru/api/session"
        let new_session_resp = this.axios({
          method: "post",
          url: session_url,
          headers: {
            "Authorization": "Basic " + btoa(`${this.$store.getters.getCurrentUser.email}:${this.$store.getters.getCurrentUser.traccar_password}`),
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: data
        })
          .then(response => {
            if (response.status != 200) {
              return
            } else {
              const url = 'https://vos-rentprog.ru';
              window.open(url, '_blank');
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$router.push({ path: `/map` }).catch((err) => { });
      }
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkManager() {
      return this.$store.getters.isManager;
    },
    checkLate() {
      let todos = this.todos.filter((todo) => todo.done != true);
      const late_todos_check = (todo) => moment().isAfter(todo.end_date, "day");
      if (!todos.some(late_todos_check)) {
        return "green";
      } else {
        return "red";
      }
    },
    replaceToProfile() {
      this.$router.push({ name: "Profile" }).catch((err) => { });
    },
    replaceToSalary() {
      this.$router.push({ name: "Salary" }).catch((err) => { });
    },
    replaceToCheckLists() {
      this.$router.push({ name: "CheckList" }).catch((err) => { });
    },
    replaceToSchedule() {
      this.$router.push({ name: "ScheduleCalendar" }).catch((err) => { });
    },
    replaceToCompanyProfile() {
      this.$router.push({ name: "CompanyProfile" }).catch((err) => { });
    },
    replaceToCompanyCounts() {
      this.$router.push({ name: "CompanyCounts" }).catch((err) => { });
    },
    async checkCrispSiteAvailable() {
      if (!$crisp || typeof $crisp == "undefined" || !$crisp.is) {
        return false;
      }
      return await $crisp.is("website:available");
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
                this.$store.getters.getCompany.company &&
                this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    signOut() {
      let self = this;
      this.axios({
        url: "users/sign_out",
        baseURL:
          process.env.NODE_ENV === "development"
            // ? "http://10.0.2.2:3000"
            ? "http://localhost:3000"
            : "https://rentprog.net",
        method: "delete",
        headers: { Authorization: self.$store.getters.getAuthToken },
      })
        .then(() => {
          this.$store.commit("unsetCurrentUser");
        })
        .catch((error) => {
          self.isLoading = false;
          self.$swal({
            title: self.$t("errors.error"),
            text: error,
            icon: "error",
          });
          console.log(error);
        });
    },
    checkAdmin() {
      return this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    reverseDrawer() {
      return this.$store.commit("setDrawer", !this.$store.getters.getDrawer);
    },
  },
  components: {
    "v-gravatar": Gravatar,
    drawer,
  },
};
</script>

<style lang="css">
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 10px !important;
}
</style>
