<template>
  <v-app id="inspire">
    <loading :active.sync="isLoading" :is-full-page="true" color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"></loading>
    <v-main>
      <!-- <v-alert v-if="error" :value="true" color="error">
        {{ error }}
      </v-alert> -->
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t("users.enter") }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="recaptcha()">
                  <v-text-field v-model.trim="email" data-cy="email" label="Email" required
                    autocomplete="email"></v-text-field>
                  <v-text-field v-model.trim="password" :append-icon="show1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                    " :type="show1 ? 'text' : 'password'" name="input-10-1" :hint="$t('users.password_length')" counter
                    @click:append="show1 = !show1" data-cy="password" :label="$t('users.password')" required
                    autocomplete="current-password"></v-text-field>
                  <v-flex class="text-center">
                    <v-btn type="submit" data-cy="submit" block color="primary">{{ $t("users.enter") }}</v-btn>
                    <div class="mt-2">
                      <router-link to="/signup">{{
                        $t("users.registration")
                      }}</router-link>
                      <br />
                      <router-link to="/forgot_password">{{
                        $t("users.password_lost")
                      }}</router-link>
                    </div>
                  </v-flex>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { AndroidBiometryStrength, BiometricAuth, BiometryError, BiometryErrorType, BiometryType, getBiometryName } from '@aparajita/capacitor-biometric-auth'
export default {
  name: "Signin",
  data() {
    return {
      email: null,
      password: null,
      error: "",
      show1: false,
      push_granted: false,
      fcm_token: null,
      isLoading: false,
    };
  },
  created() {
    if (this.isNative) {
      this.biometricIdentity();
    }
  },
  computed: {
    isNative() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ||
        Capacitor.getPlatform().toLowerCase() == "android"
        ? true
        : false;
    },
    isPushNotificationsAvailable() {
      return Capacitor.isPluginAvailable("PushNotifications");
    },
    user_role() {
      return this.$store.getters.getCurrentUser.role;
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
        .then(() => this.signin())
        .catch((error) => {
          this.setError(error, this.$t("errors.auth"));
          console.log(error);
        });
    },
    signin() {
      if ((this.email, this.password)) {
        let self = this;
        this.isLoading = true;
        let data = {
          user: {
            email: this.email,
            password: this.password,
          },
        };
        this.axios({
          url: "users/sign_in",
          baseURL:
            process.env.NODE_ENV === "development"
              // ? "http://10.0.2.2:3000"
              ? "http://localhost:3000"
              : "https://rentprog.net",
          method: "post",
          data: data,
        })
          .then((response) => {
            this.$store.commit("setUserInfo", response);
            this.$store.commit("setUserSettings", response.data.user_settings);
            Cookies.set("auth_token", response.headers.authorization, {
              expires: 365,
            });
            // если isNative сохраняем зашифрованные логин и пароль
            if (this.isNative) {
              let encodedData = {
                email: this.email,
                password: this.password,
              };
              // Generate random salt
              let salt = "4b953d62fbab81278324e71b4037eb06355dd49a";
              // Encrypt data
              let encryptedEmail = CryptoJS.AES.encrypt(this.email, salt).toString();
              let encryptedPassword = CryptoJS.AES.encrypt(this.password, salt).toString();
              // Save data
              localStorage.setItem("credentials", JSON.stringify({ email: encryptedEmail, password: encryptedPassword }));
            }
            if (
              response.data.user_settings &&
              response.data.user_settings.dark_theme
            ) {
              this.$vuetify.theme.dark = true;
            } else {
              this.$vuetify.theme.dark = false;
            }
          })
          .then(() => {
            this.getUserInfo();
          })
          .catch((error) => {
            self.isLoading = false;
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              self.$swal({
                title: self.$t("errors.enter_title"),
                html: self.$t("errors.enter_text"),
                icon: "error",
              });
            } else if (
              error.response &&
              error.response.status &&
              error.response.status == 404
            ) {
              self.$swal({
                title: self.$t("errors.enter_title"),
                text: self.$t("errors.lost_email"),
                icon: "error",
              });
            } else {
              self.$swal({
                title: self.$t("errors.enter_title"),
                text: error,
                icon: "error",
              });
            }
            console.log(error);
          });
      } else {
        this.$swal({
          title: this.$t("errors.all_fields_title"),
          text: this.$t("errors.all_fields_text"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    getUserInfo() {
      let self = this;
      this.axios
        .get("/me.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data.active && response.data.role != "agent") {
            self.$store.commit("setCurrentUser", response.data);
            // Set locale
            self.$i18n.locale = response.data.locale;
            self.signinSuccessful();
          } else if (response.data.active && response.data.role == "agent" && response.data.agent_setting) {
            if (response.data.agent_setting.access_to_account == false) {
              self.$store.commit("unsetCurrentUser");
            } else {
              self.$store.commit("setCurrentUser", response.data);
              // Set locale
              self.$i18n.locale = response.data.locale;
              self.signinSuccessful();
            }
          } else {
            self.isLoading = false;
            self.$swal({
              title: self.$t("errors.deactivation_title"),
              text: self.$t("errors.deactivation_text"),
              icon: "error",
              showCancelButton: true,
            });
          }
        })
        .catch(function (error) {
          console.log("getUserInfo", error);
          self.$store.commit("unsetCurrentUser");
          self.isLoading = false;
          self.$swal({
            title: self.$t("errors.error"),
            text: error,
            icon: "error",
          });
          // self.$router.push({ path: `/signin` }).catch(err => {});
          console.log(error);
        });
    },
    biometricIdentity() {
      if (localStorage.getItem("credentials") && localStorage.getItem("credentials") != "undefined" && localStorage.getItem("credentials") != null) {
        BiometricAuth.checkBiometry().then((result) => {
          if (result.isAvailable) {
            BiometricAuth.authenticate({
              reason: this.$t("biomentric_auth.reason"),
              cancelTitle: this.$t("biomentric_auth.cancelTitle"),
              allowDeviceCredential: false,
              iosFallbackTitle: this.$t("biomentric_auth.fallbackTitle"),
              androidTitle: this.$t("biomentric_auth.androidTitle"),
              androidSubtitle: this.$t("biomentric_auth.androidSubtitle"),
              androidConfirmationRequired: true,
              androidBiometryStrength: "WEAK",
            }).then((result) => {
              console.log(result);
              // decrypt
              let encodedData = JSON.parse(localStorage.getItem("credentials"));
              let salt = "4b953d62fbab81278324e71b4037eb06355dd49a";
              let email = CryptoJS.AES.decrypt(encodedData.email, salt).toString(CryptoJS.enc.Utf8);
              let password = CryptoJS.AES.decrypt(encodedData.password, salt).toString(CryptoJS.enc.Utf8);
              this.email = email;
              this.password = password;
              this.signin();
            }).catch((error) => {
              console.error('Error verifying identity:', error);
            });
          } else {
            alert(this.$t("biomentric_auth.not_availble"));
          }
        }).catch((e) => {
          console.error(e);
          alert(this.$t("biomentric_auth.not_recognized"));
        });
      }
    },
    signinSuccessful() {
      let self = this;
      this.isLoading = true;
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: self.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get(`api/v1/main_companies/${response.data.main_company_id}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response_main_company) => {
              self.$store.commit("setMainCompany", response_main_company.data);
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.main_company_data"));
              console.log(error);
            });
        })
        .then(() => {
          self.axios
            .get(`api/v1/doc_templates/${null}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response_data) => {
              if (response_data.data) {
                self.$store.commit("setDocUrl", response_data.data.url);
              } else {
                let doc_link =
                  self.$i18n.locale == "ru"
                    ? "https://web.rentprog.ru/doc_example.docx"
                    : "https://web.rentprog.com/doc_example_en.docx";
                self.$store.commit("setDocUrl", doc_link);
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });
          self.axios
            .get(`api/v1/doc_entity_templates/${null}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((doc_entity_response) => {
              // Если есть договор, то он, если нет, то по умолчанию шаблон
              if (doc_entity_response.data) {
                self.$store.commit(
                  "setEntityDocUrl",
                  doc_entity_response.data.url
                );
              } else {
                let doc_link =
                  self.$i18n.locale == "ru"
                    ? "https://web.rentprog.ru/entity_doc_example.docx"
                    : "https://web.rentprog.com/entity_doc_example_en.docx";
                self.$store.commit("setEntityDocUrl", doc_link);
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });
          self.axios
            .get(`api/v1/invoice_templates`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((invoice_response) => {
              // Если есть договор, то он, если нет, то по умолчанию шаблон
              if (invoice_response.data) {
                self.$store.commit("setInvoiceUrl", invoice_response.data.url);
              } else {
                self.$store.commit(
                  "setInvoiceUrl",
                  "https://web.rentprog.ru/invoice_example.docx"
                );
              }
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.docs_data"));
              console.log(error);
            });

          self.axios
            .get(`api/v1/messages`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((messages_response) => {
              self.$store.commit(
                "setMessages",
                messages_response.data.messages
              );
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.messages"));
              console.log(error);
            });
        })
        .catch((error) => {
          self.isLoading = false;
          self.setError(error, self.$t("errors.company_data"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          // Register with Apple / Google to receive push via APNS/FCM
          if (this.isPushNotificationsAvailable && this.isNative) {
            PushNotifications.register();
          }
          if (this.user_role != "agent") {
            if (this.$route.query.from) {
              this.$router.push({ path: this.$route.query.from }).catch(err => { });
            } else {
              this.$router.push({ path: `/dashboard` }).catch(err => { });
            }
          } else {
            this.$router.push({ path: `/search_free` }).catch(err => { });
          }
        });
    },
    signinFailed(error) {
      this.setError(error, this.$t("errors.auth"));
      localStorage.removeItem("credentials");
      this.$store.commit("unsetCurrentUser");
      console.log(this.error);
    },
    setError(error, text) {
      this.isLoading = false;
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>

<style lang="css"></style>
