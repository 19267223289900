import Vue from "vue";
import App from "./App";
import router from "./router";
import { store } from "./store/store";
import axios from "axios";
import VueAxios from "vue-axios";
// import firebase from "firebase/app";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import ReadMore from "vue-read-more";
import VTooltip from "v-tooltip";
import JsonExcel from "vue-json-excel";
// import IdleVue from 'idle-vue'
import ActionCableVue from "actioncable-vue";
// import DatePicker from "v-calendar/lib/components/date-picker.umd";
import VCalendar from 'v-calendar';
import "./registerServiceWorker";
import fullscreen from "vue-fullscreen";
import * as GmapVue from "gmap-vue";
import GmapCluster from "gmap-vue/dist/components/cluster";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
import Rollbar from "rollbar";
// import CrispChat from "@dansmaculotte/vue-crisp-chat";
import { Crisp } from "crisp-sdk-web";
import OneSignalVue from "onesignal-vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueSignaturePad from "vue-signature-pad";

import VueTimeago from 'vue-timeago'
import VueCountdown from '@chenfengyuan/vue-countdown';

import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import i18n from "./i18n";
// import { NewRelicCapacitorPlugin, NREnums, AgentConfiguration } from '@newrelic/newrelic-capacitor-plugin';
// import { Capacitor } from '@capacitor/core';
import { Stripe } from '@capacitor-community/stripe';

// initialize stripe
Stripe.initialize({
  publishableKey: "pk_live_51KtQkKFNgvJ2eyx925rNt6rX9DwYTt6TrcsGIjfIeE7gZWPNSgg4pEC3NKI4tJKiPqgMytdYPPHQ8WhYXtb9Ka9o00htarW7FH",
  // publishableKey: "pk_test_51KtQkKFNgvJ2eyx9Z3T7j3jeQU5V9QAMvMWVhjtpdXEcKVwsRgMvTHYCMPFKP2W7sQ74Xl5ykB8NndRd3sCQaUd6002xUquz6s",
});

import "./redactorx/redactorx.usm.min";
import "./redactorx/alignment.min";
import "./redactorx/imageposition.min";
import "./redactorx/imageresize.min";
import "./redactorx/removeformat.min";
import "./redactorx/redactorx.min.css";
import "./redactorx/ru";
import "./redactorx/en";
Vue.component("Redactorx", {
  template:
    '<textarea ref="redactorx" :name="name" :placeholder="placeholder" :value="value" />',
  redactor: !1,
  props: {
    value: { default: "", type: String },
    placeholder: { type: String, default: null },
    name: { type: String, default: null },
    config: { default: {}, type: Object },
  },
  watch: {
    value(e, t) {
      this.redactorx.editor.isFocus() || this.redactorx.app.$element.val(e);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    init() {
      var e = this,
        t = {
          "editor.change": function (t) {
            var r = t.get("html");
            return e.handleInput(r), r;
          },
        };
      void 0 === this.config.subscribe
        ? Vue.set(this.config, "subscribe", t)
        : (this.config.subscribe["editor.change"] = t["editor.change"]);
      var r = RedactorX(this.$refs.redactorx, this.config);
      (this.redactorx = r), (this.$parent.redactorx = r);
    },
    destroy() {
      RedactorX(this.$refs.redactorx, "destroy"),
        (this.redactorx = null),
        (this.$parent.redactorx = null);
    },
    handleInput(e) {
      this.$emit("input", e);
    },
  },
});

Vue.component(VueCountdown.name, VueCountdown);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";

Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

Vue.use(wysiwyg, {
  maxWidth: "700px",
  // image: {
  //   uploadURL: "/api/myEndpoint",
  //   dropzoneOptions: {}
  // },
});

import { ru, enUS, pt } from "date-fns/locale";

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'enUS', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    ru: ru,
    en: enUS,
    pt: pt,
  }
})

// Rollbar
// rollbar_token
Vue.prototype.$rollbar = new Rollbar({
  accessToken: "0d8e65c1fc2c46c7adbf3ffb755fa662",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  environment:
    process.env.NODE_ENV === "development" ? "development" : "production",
});
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(VueReCaptcha, {
  siteKey: "6Lc8F3YbAAAAAHTqXNnmCYokUy1_hCiZsiOf6zv8",
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.use(VueSignaturePad);
// Vue.component("vc-date-picker", DatePicker);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />          // ...other defaults
});

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  // debugLevel: "all",
  connectionUrl:
    process.env.NODE_ENV === "development"
      ? "ws://localhost:3000/cable"
      : "wss://rentprog.net/cable",
  connectImmediately: true,
  store,
});

Vue.component("downloadExcel", JsonExcel);
Vue.component("loading", Loading);
// Vue.use(VueMoment);
Vue.directive("mask", VueMaskDirective);

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
// Vue.axios.defaults.baseURL = 'https://rentprog.net'
Vue.axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    // ? "http://10.0.2.2:3000"
    ? "http://localhost:3000"
    : "https://rentprog.net";
// Обработка ошибок
Vue.axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status == 401 &&
      router.currentRoute.path != "/signin" &&
      router.currentRoute.path != "/signup" &&
      router.currentRoute.path != "/forgot_password" &&
      router.currentRoute.path != "/reset_password"
    ) {
      store.commit("unsetCurrentUser");
    }
    return Promise.reject(error); // this is the important part
  }
);

Vue.use(ReadMore);
Vue.use(VTooltip);
Vue.use(fullscreen);
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyBI4BMTVdrBw_p4rjxkseNpQ_F4x_sW1LE",
    libraries: "places", // This is required if you use the Autocomplete plugin
    load: {
      region: "RU",
      language: "ru",
    },
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: false,
});
Vue.component("cluster", GmapCluster);
Vue.use(VueTour);

// Vue.use(CrispChat, {
//   websiteId: "d6ec8f7f-16a7-46bc-805a-7b0d1da8962e",
//   disabled: false,
//   hideOnLoad: true,
//   session_merge: true,
// });
Crisp.configure("d6ec8f7f-16a7-46bc-805a-7b0d1da8962e", {
  autoload: true,
  hideOnLoad: true,
  session_merge: true,
});

Vue.use(OneSignalVue);

let app;

if (!app) {
  app = new Vue({
    el: "#app",
    router,
    store,
    // securedAxiosInstance,
    // plainAxiosInstance,
    components: { App },
    vuetify,
    i18n,
    // Помогло убрать ошибку runtime-only or standalone
    render: (h) => h(App),
  });
}

// Директива для ввода телефона v-phone
Vue.directive("phone", {
  bind(el) {
    function replaceNumberForInput(value) {
      let val = "";
      const x = value
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (!x[2] && x[1] !== "") {
        val = x[1] === "8" ? x[1] : "8" + x[1];
      } else {
        val = !x[3]
          ? x[1] + x[2]
          : x[1] + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "");
      }

      return val;
    }

    function replaceNumberForPaste(value) {
      const r = value.replace(/\D/g, "");
      let val = r;
      // if (val.charAt(0) === '7') {
      //   val = '8' + val.slice(1)
      // }
      return replaceNumberForInput(val);
    }

    el.oninput = function (e) {
      if (!e.isTrusted) {
        return;
      }
      this.value = replaceNumberForInput(this.value);
    };

    el.onpaste = function () {
      setTimeout(() => {
        const pasteVal = el.value;
        this.value = replaceNumberForPaste(pasteVal);
      });
    };
  },
});
